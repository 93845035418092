import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { BsClockHistory } from 'react-icons/bs';
import { BsQuestionCircle } from 'react-icons/bs';
import Swal from "sweetalert2"; // Assuming SweetAlert2 is installed
import Card from "../../../../components/card"; 

const SurveyDetails = () => {
  const { survey_id } = useParams(); // Fetch survey ID from URL
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState(null); // Store survey data
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch survey details on component mount
  useEffect(() => {
    axiosPrivate
      .get(`/survey/${survey_id}/survey-view/`)
      .then((res) => {
        setSurvey(res.data.data); // Store survey details
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching survey details:", err);
        setLoading(false);
      });
  }, [survey_id]);

  // Handle survey deletion
  const handleDelete = () => {
    Swal.fire({
        title: `Are you sure you want to delete this survey?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F559FB",
        cancelButtonColor: "#adb5bd",
        confirmButtonText: `Delete`,
        background: "#091442",
        iconColor: "#F559FB",
        color: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosPrivate
          .delete(`/survey/${survey_id}/delete-survey/`)
          .then(() => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your survey has been deleted.',
              icon: 'success',
              confirmButtonColor: "#F559FB",
              background: "#091442",
              color: "white",
            });
            navigate("/admin/surveys");
          })
          .catch((err) => {
            Swal.fire({
              title: 'Error!',
              text: 'There was a problem deleting the survey.',
              icon: 'error',
              confirmButtonColor: "#F559FB",
              background: "#091442",
              color: "white",
            });
            console.error("Error deleting survey:", err);
          });
      }
    });
};


  if (loading) return <p>Loading...</p>;

  return (
    <div className="w-full">
      {/* Edit and Delete Buttons aligned to the right and outside the Card */}
      <div className="flex justify-end mb-4 space-x-3">
        {/* Edit Button */}
        <button
          onClick={() => navigate(`/admin/surveys/edit/${survey_id}`)}
          className="mt-5 rounded-[20px] flex items-center space-x-2 bg-pgBrand-500 px-4 py-2 font-medium text-white duration-200 hover:bg-pgBrand-550"
        >
          <FiEdit2 />
          <span className="uppercase tracking-wider text-sm">Edit Survey</span>
        </button>
        {/* Delete Button */}
        <button
          onClick={handleDelete}
          className="mt-5 rounded-[20px] flex items-center space-x-2 bg-red-500 px-4 py-2 font-medium text-white duration-200 hover:bg-red-600"
        >
          <FiTrash2 />
          <span className="uppercase tracking-wider text-sm">Delete Survey</span>
        </button>
      </div>

      {/* Survey content inside the Card */}
      <Card extra="items-center w-full h-full p-[16px] bg-cover">
  {/* Survey Header with Cyan Background, Navy Text */}
  <div className="relative mt-1 flex w-full justify-center rounded-xl bg-navy-700 bg-opacity-5 py-6 px-4">
    <div className="text-center">
      <h1 className="text-3xl md:text-4xl font-body tracking-wide drop-shadow-pgBrand3 font-bold text-navy-700 dark:text-white">
        {survey?.name}
      </h1>
      {/* Survey Details in Cyan Background, Navy Text */}
      <div className="lg:flex flex-col lg:flex-row grid grid-cols-1 justify-center lg:space-x-10 mt-4 bg-pgBrand-200 rounded-[18px] p-4 lg:rounded-full border-[4px] border-white dark:!border-navy-800">
        <div className="flex items-center space-x-3 px-8 py-1 justify-between rounded-2xl border border-navy-700 border-opacity-65">
          <BsClockHistory className="w-5 h-5 text-navy-700" />
          <div className="flex space-x-2 justify-between items-center">
            <p className="text-base text-navy-700">
              Created At: {new Date(survey.created_at).toLocaleDateString()}
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-3 px-8 py-1 justify-between rounded-2xl border border-navy-700 border-opacity-65">
          <BsClockHistory className="w-5 h-5 text-navy-700" />
          <div className="flex space-x-2 justify-between items-center">
            <p className="text-base text-navy-700">
              Modified At: {new Date(survey.updated_at).toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>

        {/* Survey Description */}
        <div className="mt-8 w-full p-4 bg-navy-800 text-white border border-pgBrand-500 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold">Survey Description</h2>
          <p className="text-lg mt-2">{survey?.description || "No description provided."}</p>
        </div>

        {/* Survey Settings */}
        <div className="mt-8 w-full p-4 bg-navy-800 text-white border border-pgBrand-500 rounded-lg shadow-md">
          <h4 className="text-3xl font-bold">Survey Settings</h4>
          <ul className="text-lg mt-2">
            <li>
              <strong>Editable:</strong> {survey.editable ? "Yes" : "No"}
            </li>
            <li>
              <strong>Deleteable:</strong> {survey.deleteable ? "Yes" : "No"}
            </li>
            <li>
              <strong>Private Responses:</strong> {survey.private_response ? "Yes" : "No"}
            </li>
            <li>
              <strong>Anonymous User Access:</strong> {survey.can_anonymous_user ? "Yes" : "No"}
            </li>
            <li>
              <strong>Notification To:</strong> {survey.notification_to || "N/A"}
            </li>
          </ul>
        </div>

        {/* Survey Questions */}
        <div className="mt-8 w-full">
          <h3 className="text-3xl font-bold text-white">Survey Questions</h3>
          {survey.questions.length > 0 ? (
            <div className="mt-4 space-y-4">
              {survey.questions.map((question) => (
                <div
                  key={question.id}
                  className="p-4 bg-navy-800 text-white border border-pgBrand-500 rounded-lg shadow-md"
                >
                  <div className="flex items-center">
                    <p className="text-2xl font-semibold">
                      {question.ordering}. {question.label}
                    </p>
                    {question.help_text && (
                      <BsQuestionCircle
                        className="ml-2 w-5 h-5 text-gray-400 cursor-pointer"
                        title={question.help_text} // Tooltip shows the help text when hovered over
                      />
                    )}
                  </div>
                  {question.type_field === 3 && question.choices && (
                    <p className="text-lg mt-2">
                      <strong>Choices:</strong> {question.choices}
                    </p>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-300">No questions available.</p>
          )}
        </div>
      </Card>
    </div>
  );
};

export default SurveyDetails;
