import PlaygroundCard from "../../../components/card/PlaygroundCard";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "../../../components/dropdown";
import { FaPlus } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import SVG from "react-inlinesvg";

import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { HiX } from "react-icons/hi";
import LoadingCard from "../../../components/card/LoadingCard";

const Playground = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // let filter = location.state?.filter ? location.state.filter : "";
  const axiosPrivate = useAxiosPrivate();
  const [playgrounds, setPlaygrounds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [coverImg, setCoverImg] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [statusQuery, setStatusQuery] = useState(null);
  const [categoryQuery, setCategoryQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(9); // Adjust page size as needed
  const [hasMore, setHasMore] = useState(false); // State to track if more pages are available


  const [open, setOpen] = useState(false);

  // const [searchQuery, setSearchQuery] = useState(filter);
  // const [selectedFilter, setSelectedFilter] = useState(filter);

  useEffect(() => {
    setLoading(true);
    
    // Fetch statuses
    axiosPrivate.get("/facility/status/").then((res) => {
      setStatuses(res.data.data);
    });
  
    // Fetch playgrounds based on filters
    axiosPrivate
      .get(`/facility/playgrounds/?name=${searchQuery}&status=${statusQuery ? statusQuery.id : ""}&category=${categoryQuery}&page=${page}&page_size=${pageSize}`)
      .then((res) => {
        setLoading(false);
        setPlaygrounds(res.data.data);
        setHasMore(res.data.has_more); // Update hasMore based on the API response
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [categoryQuery, searchQuery, statusQuery, page, pageSize]);
  

  useEffect(() => {
    axiosPrivate
      .get("/facility/categories/")
      .then((res) => {
        //  console.log(res.data);
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        {/* NFt Banner */}
        {/* <Banner /> */}

        {/* NFt Header */}

        <div
          className="mb-4 mt-5 w-full px-2 py-2 flex flex-col rounded-[20px] h-fit md:h-[58px]
         justify-between  md:flex-row md:items-center  md:gap-1 xl:w-full xl:gap-2"
        >
          <div
            className="relative w-full md:w-3/4 3xl:w-2/3 flex h-full  flex-grow items-center justify-start
           gap-2 bg-white  shadow-xl shadow-shadow-500
            dark:bg-opacity-0 dark:shadow-none  md:flex-grow-0 "
          >
            <div
              className="flex h-full items-center py-2 w-full  md:w-1/2  rounded-[20px] bg-pgBrand-200  
             text-navy-700 bg-opacity-10 border border-pgBrand-250 dark:text-navy-700
              "
            >
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-pgBrand-200" />
              </p>
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search..."
                className="block h-full w-full rounded-[20px] bg-lightPrimary text-sm font-medium 
                text-pgBrand-250 outline-none placeholder:!text-gray-400 dark:bg-pgBrand-200 
                tracking-wide dark:bg-opacity-0
              dark:text-white dark:placeholder:!text-pgBrand-200 sm:w-full"
              />
            </div>
            <Dropdown
              button={
                <button
                  onClick={() => setOpen(!open)}
                  open={open}
                  className={`flex items-center text-base hover:cursor-pointer 
                             "bg-none text-white hover:bg-none active:bg-none"
                           linear justify-center rounded-lg ml-4  transition duration-200`}
                >
                  <HiOutlineAdjustmentsHorizontal className="h-6 w-6 text-pgBrand-200" />
                </button>
              }
              animation={
                "origin-top-right md:origin-top-left transition-all duration-300 ease-in-out"
              }
              classNames={`${"top-11"} right-0 md:left-0 w-max`}
              children={
                <div className="z-50 w-max flex rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <div className="">
                    <h3 className="text-pgBrand-200 text-base px-3 mb-1 ">
                      Activity
                    </h3>
                    <div className="border-r pr-2 ">
                      {categories?.map((cat, index) => {
                        return (
                          <li
                            key={cat.id}
                            className={`flex items-center gap-4 text-base capitalize font-medium rounded-2xl px-3 py-1 bg-pgBrand-500 bg-opacity-0 hover:bg-opacity-20
                                ${
                                  cat.name === categoryQuery
                                    ? " bg-opacity-100 "
                                    : ""
                                } dark:text-white`}
                            onClick={() => {
                              setCategoryQuery(cat.name);
                            }}
                          >
                            <SVG
                              src={cat.icon}
                              alt="My SVG"
                              preProcessor={(code) =>
                                code.replace(
                                  /fill=".*?"/g,
                                  'fill="currentColor"'
                                )
                              }
                              className="w-5 h-5"
                            ></SVG>
                            <span>{cat.name}</span>
                          </li>
                        );
                      })}
                    </div>
                  </div>
                  <div className="ml-3 px-2">
                    <h3 className="text-pgBrand-200 text-base px-3 mb-1 ">
                      Status
                    </h3>
                    {statuses?.map((status, index) => {
                      return (
                        <li
                          key={status.id}
                          className={`flex items-center gap-4 text-base capitalize font-medium rounded-2xl px-3 py-1 bg-pgBrand-500 bg-opacity-0 hover:bg-opacity-20
                                ${
                                  status.id === statusQuery?.id
                                    ? " bg-opacity-100 "
                                    : ""
                                } dark:text-white`}
                          onClick={() => {
                            setStatusQuery(status);
                          }}
                        >
                          <span>{status.name}</span>
                        </li>
                      );
                    })}
                  </div>
                </div>
              }
            />
            {categoryQuery ? (
              <span
                className={`flex justify-between w-fit space-x-3 tracking-wide items-center rounded-xl
                                        drop-shadow-sm bg-opacity-10 px-2 py-1
                                      text-xs font-medium  ring-1 ring-inset
                                      bg-yellow-50 text-pgBrand-200  ring-pgBrand-200`}
              >
                <div
                  className={`rounded-full text-lg cursor-pointer`}
                  onClick={() => {
                    setCategoryQuery("");
                  }}
                >
                  <HiX className="text-pgBrand-200 w-4 h-4" />
                </div>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <span className="text-sm font-light mr-1">Activity :</span>
                  {categoryQuery}
                </p>
              </span>
            ) : (
              ""
            )}{" "}
            {statusQuery ? (
              <span
                className={`flex justify-between w-fit space-x-3 tracking-wide items-center rounded-xl
                                        drop-shadow-sm bg-opacity-10 px-2 py-1
                                      text-xs font-medium  ring-1 ring-inset
                                      bg-yellow-50 text-pgBrand-200  ring-pgBrand-200`}
              >
                <div
                  className={`rounded-full text-lg cursor-pointer`}
                  onClick={() => {
                    setStatusQuery("");
                  }}
                >
                  <HiX className="text-pgBrand-200 w-4 h-4" />
                </div>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <span className="text-sm font-light mr-1">Status :</span>
                  {statusQuery.name}
                </p>
              </span>
            ) : (
              ""
            )}
          </div>

          <button
            onClick={() => navigate("/admin/playgrounds/addPlayground")}
            className="linear group w-full mt-2 md:mt-0 text-center md:w-fit rounded-[20px] flex justify-center items-center
             space-x-3 bg-pgBrand-500 px-8 py-2  font-medium text-white  
          duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
          dark:hover:bg-pgBrand-550 dark:active:opacity-90"
          >
            <FaPlus className="group-hover:rotate-90 transition-transform duration-300 " />
            <span className="uppercase tracking-wider text-sm ">
              Add Playground
            </span>
          </button>
        </div>

        {/* NFTs trending card */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
          {loading ? (
            <>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </>
          ) : playgrounds.length > 0 ? (
            playgrounds.map((pg) => {
              return (
                <PlaygroundCard
                extra={"border border-pgBrand-500"}
                id={pg.id}
                key={pg.id}
                name={pg.name}
                category={pg.category}
                image={pg.images.cover_image}
                status={pg.status}
                average_rating={pg.average_rating} // Pass the average rating
                total_reviews_count={pg.review_count} // Pass the total reviews count
              />
              );
            })
          ) : (
            <h4 className="text-2xl font-normal ml-7 mt-10  text-navy-700 dark:text-white">
              No Results Found
            </h4>
          )}
        </div>
        <div className="pagination-buttons mt-4 flex justify-between">
          <button
            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))} // Decrease page, but not less than 1
            className="bg-pgBrand-200 text-white p-3 rounded-md w-32 text-lg font-semibold"
            disabled={page === 1} // Disable the button if on the first page
          >
            Previous
          </button>
          <button
            onClick={() => setPage((prevPage) => prevPage + 1)} // Increase page
            className="bg-pgBrand-200 text-white p-3 rounded-md w-32 text-lg font-semibold"
            disabled={!hasMore} // Disable "Next" button if there are no more pages
          >
            Next
          </button>
        </div>


        {/* Recenlty Added setion */}
        {/* <div className="mb-5 mt-9 flex items-center justify-between px-[26px]">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Recently Added
          </h4>
        </div> */}

        {/* Recently Add NFTs */}
        {/* <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          {filteredPlaygrounds
            .map((pg) => {
              return (
                <PlaygroundCard
                  id={pg.id}
                  name={pg.name}
                  // supervisor={pg.supervisor}
                  category={pg.category}
                  status={pg.status}
                  facility={pg.facility}
                  // address={pg.location?.address}
                  image={pg.images.cover_image}
                  // image={pg.images
                  //   .filter((img) => img.is_cover_image)
                  //   .map((img) => {
                  //     return img.image;
                  //   })}
                />
              );
            })
            .reverse()}
        </div> */}
      </div>

      {/* right side section */}

      {/* <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        <TopCreatorTable
          extra="mb-5"
          tableData={tableDataTopCreators}
          columnsData={tableColumnsTopCreators}
        />
        <HistoryCard />
      </div> */}
    </div>
  );
};

export default Playground;
