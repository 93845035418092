import ActivityCard from "../../../components/card/ActivityCard";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import { Form, Formik } from "formik";
import SVG from "react-inlinesvg";

const Activities = () => {
  const axiosPrivate = useAxiosPrivate();
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [file, setFile] = useState();

  const navigate = useNavigate();

  const [activityData, setActivityData] = useState({});
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [modalEditable, setModalEditable] = useState(false);

  const getActivities = () => {
    axiosPrivate
      .get(`/facility/categories/?search=${searchQuery}`)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
    setModalEditable(false);
    setFile();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const getActivity = (id) => {
    axiosPrivate
      .get(`/facility/${id}/view-category/`)
      .then((res) => {
        console.log(res.data.data);
  
        // Construct the full URL for the icon
        const fullIconUrl = `https://api.mubarti.streamline.com.ly${res.data.data.icon}`;
        setActivityData({
          ...res.data.data,
          icon: fullIconUrl, // Set the full URL for the icon
        });
      })
      .then(() => handleModalOpen())
      .catch((error) => {
        console.error("Error fetching activity data:", error);
      });
  };
  

  const getUpdatedActivity = (id) => {
    axiosPrivate.get(`/facility/${id}/view-category/`).then((res) => {
      //  console.log(res.data.data);
      setActivityData(res.data.data);
    });
  };

  const updateActivity = (data) => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    if (data.icon_url) {
        bodyFormData.append("icon_url", data.icon_url);
    }
    if (data.description) {
        bodyFormData.append("description", data.description);
    }

    axiosPrivate
        .patch(`/facility/${activityData.id}/update-category/`, bodyFormData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            setLoading(false);
            setModalEditable(false);
            getUpdatedActivity(activityData.id);
            setModalOpen(false);

            // Show success message after the modal closes
            Swal.fire({
                icon: "success",
                title: "Activity has been updated successfully!",
                confirmButtonText: "Go To Activities List",
                confirmButtonColor: "#34EDFF",
                background: "#091442",
                color: "white",
            }).then((response) => {
                getActivities(); // Fetch updated activities after user closes the alert
            });
        })
        .catch((err) => {
            setLoading(false);
            // console.error("Error Details:", err);
            setModalEditable(false);
            setModalOpen(false);


            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response?.data?.message || "An error occurred. Please try again.",
                confirmButtonText: "Go Back",
                confirmButtonColor: "#45acc4",
            });
        });
};


  useEffect(() => {
    getActivities();
  }, [searchQuery]);

  const deleteActivity = (id) => {
    handleModalOpen();
    Swal.fire({
      title: `Are you sure you want to delete <span class="text-pgBrand-500">${activityData.name} </span> activty? `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F559FB",
      cancelButtonColor: "#adb5bd",
      confirmButtonText: `Delete Activity`,
      background: "#091442",

      iconColor: "#F559FB",
      color: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosPrivate
          .delete(`/facility/${id}/delete-category/`)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Activity deleted successfully!",
              confirmButtonText: "Ok",
              confirmButtonColor: "#34EDFF",
              background: "#091442",
              color: "white",
            }).then((response) => {
              getActivities();
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
              confirmButtonText: "Go Back",
              confirmButtonColor: "#45acc4",
              background: "#091442",
              color: "white",
            });
          });
      }
    });
  };

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5">
      <div className="col-span-1  h-full w-full xl:col-span-1 2xl:col-span-2">
        {/* NFt Banner */}
        {/* <Banner /> */}

        {/* NFt Header */}
        <div
          className="mb-4 mt-5 w-full px-2 py-2 flex flex-col rounded-[20px] h-fit md:h-[58px]
         justify-between  md:flex-row md:items-center  md:gap-1 xl:w-full xl:gap-2"
        >
          <div
            className="relative w-full md:w-3/4 3xl:w-2/3 flex h-full  flex-grow items-center justify-start
           gap-2 bg-white  shadow-xl shadow-shadow-500
            dark:bg-opacity-0 dark:shadow-none  md:flex-grow-0 "
          >
            <div
              className="flex h-full items-center py-2 w-full  md:w-1/2  rounded-[20px] bg-pgBrand-200  
             text-navy-700 bg-opacity-10 border border-pgBrand-250 dark:text-navy-700
              "
            >
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-pgBrand-200" />
              </p>
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search..."
                className="block h-full w-full rounded-[20px] bg-lightPrimary text-sm font-medium 
                text-pgBrand-250 outline-none placeholder:!text-gray-400 dark:bg-pgBrand-200 
                tracking-wide dark:bg-opacity-0
              dark:text-white dark:placeholder:!text-pgBrand-200 sm:w-full"
              />
            </div>
          </div>

          <button
            onClick={() => navigate("/admin/activities/addActivity")}
            className="linear group w-full mt-2 md:mt-0 text-center md:w-fit rounded-[20px] flex justify-center items-center
             space-x-3 bg-pgBrand-500 px-8 py-2  font-medium text-white  
          duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
          dark:hover:bg-pgBrand-550 dark:active:opacity-90"
          >
            <FaPlus className="group-hover:rotate-90 transition-transform duration-300 " />
            <span className="uppercase tracking-wider text-sm ">Add New</span>
          </button>
        </div>

        {/* NFTs trending card */}
        <div
          className="z-20 grid grid-cols-2 mt-6  gap-5 md:grid-cols-3
         lg:grid-cols-4 xl:grid-cols-6 3xl:grid-cols-6"
        >
          {categories.map((category) => {
            return (
              <ActivityCard
                key={category.id}
                id={category.id}
                name={category.name}
                icon={category.icon}
                getActivity={() => getActivity(category.id)}
              />
            );
          })}
        </div>
      </div>

      <Dialog
        size="md"
        open={modalOpen}
        // handler={handleModalOpen}
        className="bg-navy-700 border !z-40 border-pgBrand-200 my-2 h-[85vh] overflow-y-scroll md:h-auto md:overflow-hidden text-center text-white rounded-xl"
      >
        <DialogHeader className="justify-between">
          {!modalEditable ? (
            <div className="flex space-x-2">
              <Button
                onClick={() => setModalEditable(true)}
                className="linear group w-fit rounded-xl px-6 py-2 tracking-wider flex items-center space-x-3 bg-pgBrand-500 lg:px-8 lg:py-2   font-medium text-white  
          duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550  dark:bg-pgBrand-500 
          dark:hover:bg-pgBrand-550 dark:active:opacity-90 text-xs"
              >
                Edit
              </Button>
              <Button
                onClick={() => deleteActivity(activityData.id)}
                className="linear group w-fit rounded-xl  tracking-wider flex items-center space-x-3
                 border border-red-500  px-4 py-2 lg:px-8 lg:py-2 font-medium text-red-500   
          duration-200 hover:bg-red-600 active:bg-red-600 text-xs 
          dark:hover:bg-red-600 hover:text-white dark:active:opacity-90"
              >
                Delete
              </Button>
            </div>
          ) : (
            <div></div>
          )}

          <IconButton
            color="blue-gray"
            size="sm"
            variant="text"
            onClick={handleModalOpen}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </DialogHeader>
        <Formik initialValues={activityData} onSubmit={updateActivity}>
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <DialogBody>
                <div
                  className="relative mt-1 flex flex-col w-full justify-center rounded-xl bg-cover bg-center px-4 
     "
                >
                  <div
                    className=" flex mx-auto h-[180px] w-[180px] items-center justify-center
                    rounded-full border-[8px] border-white  dark:!border-navy-900 text-pgBrand-500">
                    {modalEditable ? (
                      <>
                        {" "}
                        {file ? (
                          <span className="text-pgBrand-500 ">
                            <SVG
                              src={file}
                              alt="My SVG"
                              preProcessor={(code) =>
                                code.replace(
                                  /fill=".*?"/g,
                                  'fill="currentColor"'
                                )
                              }
                              className="w-full h-full"
                            ></SVG>
                          </span>
                        ) : (
                          <SVG
                            src={activityData.icon}
                            alt="My SVG"
                            preProcessor={(code) =>
                              code.replace(/fill=".*?"/g, 'fill="currentColor"')
                            }
                            className="w-full h-full"
                          ></SVG>
                        )}
                      </>
                    ) : (
                      <SVG
                        src={activityData.icon}
                        alt="My SVG"
                        preProcessor={(code) =>
                          code.replace(/fill=".*?"/g, 'fill="currentColor"')
                        }
                        className="w-full h-full"
                      ></SVG>
                    )}
                  </div>
                  {modalEditable ? (
                    <div className="input_field flex flex-col w-max mt-4 mx-auto text-center">
                      <label>
                        <input
                          type="file"
                          id="logoU"
                          hidden
                          accept=".svg"
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            if (file) {
                              setFieldValue("icon_url", file);
                              setFile(URL.createObjectURL(file));
                            }
                          }}
                        />
                        {errors.icon_url && touched.icon_url && (
                          <div>{errors.icon_url}</div>
                        )}
                        <div
                          className="text bg-pgBrand-500 text-white rounded
                           text-xs tracking-wider cursor-pointer p-1 px-6 hover:bg-pgBrand-550"
                        >
                          Change Icon
                        </div>
                      </label>

                      <span className="text-xs tracking-wider mt-2 text-slate-400">
                        only SVG files accepted
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="w-full">
                  {modalEditable ? (
                    <div className="w-full">
                      <div className="text-xl  font-bold text-navy-700 dark:text-white mt-8">
                        Edit Activity Info
                      </div>
                      <div className="flex flex-col w-full justify-center items-center mt-5">
                        <div className="relative w-2/3  ">
                          <input
                            required
                            id="name"
                            defaultValue={activityData.name}
                            onChange={(e) =>
                              setFieldValue("name", e.target.value)
                            }
                            name="name"
                            className="block px-2.5 py-2 w-full  text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                          dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                            focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=""
                          />
                          <label
                            htmlFor="name"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                            transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-700 px-2
                            peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                            peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                            peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                            rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Name
                          </label>
                        </div>
                        <div className="relative w-2/3 mt-4 ">
                          <textarea
                            id="description"
                            defaultValue={activityData.description}
                            onChange={(e) =>
                              setFieldValue("description", e.target.value)
                            }
                            name="description"
                            className="block px-2.5 py-2 w-full min-h-28 text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                          dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                            focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=""
                          />
                          <label
                            htmlFor="description"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                            transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-700 px-2
                            peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                            peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                            peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                            rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Description
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-8 w-full justify-center items-center flex flex-col  px-2 lg:px-10">
                      <h4 className="text-3xl font-mono text-center tracking-wider  font-bold text-navy-700 dark:text-white">
                        {activityData?.name}
                      </h4>

                      <div className=" px-4 mt-6 text-left flex  flex-col lg:flex-row justify-center items-start gap-2 lg:w-3/4">
                        <p className="text-slate-200 tracking-wider  font-semibold">
                          Description:
                        </p>
                        <p className="lg:text-lg font-mono font-light  tracking-wider  text-navy-700 dark:text-white">
                          {activityData?.description
                            ? activityData.description
                            : "-"}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </DialogBody>
              <DialogFooter className=" mt-4 lg:mt-10 justify-between lg:justify-end">
                {modalEditable ? (
                  <>
                    <Button
                      variant="outlined"
                      color="red"
                      type="button"
                      onClick={() => setModalEditable(false)}
                      className="mr-1"
                    >
                      <span>Cancel</span>
                    </Button>
                    <Button
                      variant="gradient"
                      color="green"
                      type="submit"
                      loading={loading}
                      disabled={loading}
                    >
                      <span>Confirm</span>
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </DialogFooter>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default Activities;
