import { useState } from "react";
import Card from ".";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import { PiDotsThreeBold } from "react-icons/pi";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";


const FacilityCard = ({
  id,
  name,
  supervisor,
  categories,
  image,
  address,
  extra,
  review_count = 0,
  average_rating = 0.0
}) => {
  const navigate = useNavigate();
  const [heart, setHeart] = useState(true);

  // Helper function to render stars based on the rating
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return (
      <div className="flex space-x-1">
        {Array(fullStars).fill().map((_, index) => (
          <FaStar key={index} className="text-pgBrand-200" />
        ))}
        {halfStar ? <FaStarHalfAlt className="text-pgBrand-200" /> : null}
        {Array(emptyStars).fill().map((_, index) => (
          <FaRegStar key={index} className="text-pgBrand-200" />
        ))}
      </div>
    );
  };


  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white ${extra}`}
    >
      <div className="h-full w-full">
        <div className="relative w-full">
          <img
            src={image}
            className="mb-3 h-[250px] 3xl:h-[300px] w-full rounded-xl bg-contain"
            alt=""
            onClick={() => {
              navigate(`/admin/facilities/${id}`);
            }}
          />
          <div className="absolute top-3 right-3 flex flex-col space-y-1">
            {categories
              ?.map((cat, index) => {
                return index < 3 ? (
                  <div
                    key={cat.id}
                    className="flex items-center justify-center rounded-full bg-pgBrand-200 bg-opacity-80 border-pgBrand-200 border-2 p-1 text-brand-500 hover:cursor-pointer"
                  >
                    <div
                      className="flex justify-center h-full w-full items-center rounded-full text-xl dark:text-pgBrand-250"
                    >
                      <span className="inline-flex space-x-1 uppercase tracking-wide items-center rounded-xl drop-shadow-sm bg-opacity-10 px-2 text-xs font-semibold text-navy-800 ring-inset">
                        <SVG
                          src={cat.icon}
                          alt="My SVG"
                          preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
                          className="w-5 h-5"
                        ></SVG>
                        <span>{cat.name ? cat.name : "Sport"}</span>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    key={cat.id}
                    className="flex items-center justify-center rounded-full bg-pgBrand-200 bg-opacity-75 border-pgBrand-200 border-2 border-opacity-75"
                  >
                    <span className="inline-flex space-x-1 uppercase tracking-wide items-center rounded-xl drop-shadow-sm bg-opacity-10 px-2 font-semibold text-navy-800 ring-inset">
                      <PiDotsThreeBold className="w-5 h-5" />
                    </span>
                  </div>
                );
              })
              .slice(0, 4)}
          </div>
        </div>

        <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-2 w-full">
            <div className="flex justify-between">
              <p className="text-2xl font-bold font-mono text-navy-700 dark:text-white">
                {name}
              </p>
            </div>
          </div>
        </div>

        {/* Review Rating and Count Section */}
        <div className="mt-4">
          <p className="text-md font-bold text-gray-700 dark:text-white">
            Reviews: {review_count}
          </p>
          <div className="average-rating flex items-center mt-1">
            <p className="text-md font-bold text-gray-700 dark:text-white">Rating: </p>
            <span className="ml-2 text-lg font-semibold flex items-center">
              {renderStars(average_rating)}
              <span className="ml-2 text-md">({average_rating.toFixed(1)})</span>
            </span>
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={() => {
              navigate(`/admin/facilities/${id}`);
            }}
            className="linear rounded-[20px] bg-pgBrand-200 px-4 py-2 text-lg tracking-wide font-medium text-white transition duration-200 hover:bg-pgBrand-550 dark:bg-pgBrand-500 dark:hover:bg-pgBrand-550 dark:active:opacity-90"
          >
            View Facility
          </button>
        </div>
      </div>
    </Card>
  );
};

export default FacilityCard;
