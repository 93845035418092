import React, { useEffect, useState } from "react";
import Card from "../../../../components/card";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { MdBlockFlipped, MdCancel, MdOutlineCancel } from "react-icons/md";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";

import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import { Form, Formik } from "formik";
import Datepicker from "react-tailwindcss-datepicker";
import { BsClock } from "react-icons/bs";

function EditPlayground() {
  const navigate = useNavigate();
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [playground, setPlayground] = useState({});

  const [activeStep, setActiveStep] = useState(1);
  const [pCategory, setPCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [playgroundImage, setPlaygroundImage] = useState(
    playground?.images?.cover_image || null
  );

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const [formValues, setFormValues] = useState({});
  const [updatedValues, setUpdatedValues] = useState({});
  const [loading, setLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFacility, setSelectedFacility] = useState("");

  useEffect(() => {
    axiosPrivate
      .get(`/facility/${id}/playground-view/`)
      .then((res) => {
        console.log(res.data.data);
        setPlayground(res.data.data);
        setPCategory(res.data.data.category);
        setPlaygroundImage(res.data.data.images.cover_image);
      })
      .catch((err) => {
        console.error(err);
      });
    axiosPrivate
      .get("/facility/categories/")
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
    axiosPrivate
      .get("/facility/facilities/")
      .then((res) => {
        setFacilities(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
    axiosPrivate
      .get("/facility/status/")
      .then((res) => {
        setStatuses(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
    //  console.log(pCategory);
  }, [activeStep]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const EditPlayground = (data) => {
    // setLoading(true);
    console.log(formValues);
    var formData = new FormData();
    // const fields = [
    //   "name",
    //   "supervisor",
    //   "phone",
    //   "status",
    //   "category",
    //   "open",
    //   "closed",
    //   "price",
    //   "capacity",
    //   "facility",
    //   "time_slot",
    //   "images",
    // ];

    if (data.images) {
      for (let i = 0; i < data.images.length; i++) {
        // //  console.log(data.images);
        for (let key of Object.keys(data.images[i])) {
          // //  console.log(key);
          if (key === "image") {
            formData.append(`images[${i}].${key}`, data.images[i][key][0]);
          } else {
            formData.append(`images[${i}].${key}`, data.images[i][key]);
          }
        }
      }
    }
    for (const key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        formData.append(key, formValues[key]);
      }
    }
    //  console.log(formData);

    // for (const [key, value] of formData.entries()) {
    //    console.log(key, value);
    // }

    axiosPrivate
      .patch(`/facility/${id}/playground-update/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Playground has been updated successfully!",
          confirmButtonText: "Go To Playground",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        }).then((response) => {
          navigate(`/admin/playgrounds/${id}`);
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Go Back",
          confirmButtonColor: "#45acc4",
          background: "#091442",
          color: "white",
        });
      });
  };
  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
      <Formik onSubmit={EditPlayground} initialValues={playground}>
        {({ errors, touched, values, isValid, setFieldValue }) => (
          <Form>
            <Card extra={"w-full h-full p-2 lg:p-6 sm:overflow-x-auto"}>
              {/* Stepper */}
              <Tabs value={activeStep}>
                <TabsHeader
                  className="bg-navy-900 rounded-xl !text-white"
                  indicatorProps={{
                    className:
                      "bg-pgBrand-500 rounded-xl shadow-none !text-navy-800",
                  }}
                >
                  <Tab value={1} onClick={() => setActiveStep(1)}>
                    Information
                  </Tab>
                  <Tab value={2} onClick={() => setActiveStep(2)}>
                    Images
                  </Tab>
                </TabsHeader>
                <TabsBody>
                  <TabPanel value={1}>
                    <div className="mt-2 h-full overflow-x-scroll xl:overflow-hidden py-2">
                      <div className="">
                        <h2 className="capitalize w-full ">name</h2>
                      </div>
                      <div className="w-full grid grid-cols-1  gap-4 mt-2">
                        <div className="relative">
                          <input
                            type="text"
                            required
                            id="name"
                            name="name"
                            defaultValue={playground?.name}
                            onChange={handleChange}
                            className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=""
                          />
                          <label
                            htmlFor="name"
                            className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Playground Name
                          </label>
                        </div>
                      </div>
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                        <div className="relative">
                          <input
                            type="text"
                            id="supervisor"
                            defaultValue={playground?.supervisor}
                            onChange={handleChange}
                            name="supervisor"
                            className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=""
                          />
                          <label
                            htmlFor="supervisor"
                            className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Playground supervisor
                          </label>
                        </div>

                        <div className="relative ">
                          <input
                            type="text"
                            id="phone"
                            required
                            defaultValue={playground?.phone}
                            onChange={handleChange}
                            name="phone"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="phone"
                            className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Phone Number
                          </label>
                        </div>
                      </div>

                      <div className=""></div>
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:gap-4 ">
                        <div>
                          <h2 className="capitalize w-full mt-4 lg:mt-6 mb-4">
                            Category{" "}
                          </h2>
                          <div className="relative ">
                            <select
                              id="category"
                              name="category"
                              required
                              onChange={(e) => {
                                handleChange(e);
                                setSelectedCategory(
                                  e.target.selectedOptions[0].innerHTML
                                );
                              }}
                              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200 appearance-none dark:text-white dark:bg-navy-800
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                              placeholder=""
                            >
                              <option disabled>Select A Category</option>
                              {categories?.map((category) => {
                                return (
                                  <option
                                    value={category.id}
                                    key={category.id}
                                    selected={
                                      parseInt(category.id) ===
                                      parseInt(pCategory?.id)
                                    }
                                  >
                                    {category.name}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="category"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              Category
                            </label>
                            <div className="flex space-x-2 mt-3">
                              {selectedCategory ? (
                                <span
                                  className="flex justify-between w-fit space-x-3 tracking-wide items-center rounded-xl
                          drop-shadow-sm bg-opacity-10 px-2 py-1
                         text-xs font-medium  ring-1 ring-inset ring-pgBrand-200"
                                >
                                  <div
                                    className={`rounded-full text-lg cursor-pointer`}
                                    onClick={() => {
                                      setSelectedCategory("");
                                    }}
                                  >
                                    <MdOutlineCancel className="text-gray-50" />
                                  </div>
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {selectedCategory}
                                  </p>
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <h2 className="capitalize w-full mt-2 lg:mt-6 mb-4">
                            Facility{" "}
                          </h2>
                          <div className="relative ">
                            <select
                              id="facility"
                              name="facility"
                              required
                              onChange={(e) => {
                                handleChange(e);
                                setSelectedFacility(
                                  e.target.selectedOptions[0].innerHTML
                                );
                              }}
                              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200 appearance-none dark:text-white dark:bg-navy-800
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                              placeholder=""
                            >
                              <option defaultValue disabled>
                                Select A Facility
                              </option>
                              {facilities.map((facility) => {
                                return (
                                  <option
                                    value={facility.id}
                                    key={facility.id}
                                    selected={
                                      parseInt(facility.id) ===
                                      parseInt(playground?.facility?.id)
                                        ? true
                                        : false
                                    }
                                  >
                                    {facility.name}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="facility"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              Facility
                            </label>
                            <div className="flex space-x-2 mt-3">
                              {selectedFacility ? (
                                <span
                                  className="flex justify-between w-fit space-x-3 tracking-wide items-center rounded-xl
                          drop-shadow-sm bg-opacity-10 px-2 py-1
                         text-xs font-medium  ring-1 ring-inset ring-pgBrand-200"
                                >
                                  <div
                                    className={`rounded-full text-lg cursor-pointer`}
                                    onClick={() => {
                                      setSelectedFacility("");
                                    }}
                                  >
                                    <MdOutlineCancel className="text-gray-50" />
                                  </div>
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {selectedFacility}
                                  </p>
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <h2 className="capitalize w-full mt-2 lg:mt-8">
                          Working Hours
                        </h2>
                      </div>

                      <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                        <div className="relative">
                          <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                            <svg
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="rgb(0 208 231)"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <input
                            type="time"
                            id="open"
                            defaultValue={playground?.open}
                            name="open"
                            onChange={handleChange}
                            className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer
                  "
                            required
                          />
                          <label
                            htmlFor="open"
                            className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Open
                          </label>
                        </div>

                        <div className="relative">
                          <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                            <svg
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="rgb(0 208 231)"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <input
                            type="time"
                            id="closed"
                            defaultValue={playground?.closed}
                            onChange={handleChange}
                            name="closed"
                            className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer
                  "
                            required
                          />
                          <label
                            htmlFor="closed"
                            className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Close
                          </label>
                        </div>
                        <div className="relative ">
                          <select
                            id="status"
                            name="status"
                            onChange={handleChange}
                            required
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200  dark:text-white dark:bg-navy-800
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=""
                          >
                            <option defaultValue disabled>
                              Select The Current Status
                            </option>
                            {statuses?.map((status) => {
                              return (
                                <option
                                  value={status.id}
                                  selected={
                                    parseInt(status.id) ===
                                    parseInt(playground?.status?.id)
                                      ? true
                                      : false
                                  }
                                  key={status.id}
                                >
                                  {status.name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            htmlFor="status"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Current Status
                          </label>
                        </div>
                        <div className="relative ">
                          <input
                            type="number"
                            id="capacity"
                            defaultValue={playground?.capacity}
                            required
                            onChange={handleChange}
                            name="capacity"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="capacity"
                            className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Capacity (Number of Players)
                          </label>
                        </div>
                        <div className="relative ">
                          <select
                            id="time_slot"
                            required
                            onChange={handleChange}
                            name="time_slot"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200  dark:text-white dark:bg-navy-800
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=""
                          >
                            <option value="10">10 Minutes</option>
                            <option value="15">15 Minutes</option>
                            <option value="20">20 Minutes</option>
                            <option value="25">25 Minutes</option>
                            <option value="30">30 Minutes</option>
                            <option value="35">35 Minutes</option>
                            <option value="40">40 Minutes</option>
                            <option value="45">45 Minutes</option>
                            <option value="50">50 Minutes</option>
                            <option value="55">55 Minutes</option>
                            <option value="60">60 Minutes</option>
                          </select>
                          <label
                            htmlFor="time_slot"
                            className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Time Slot
                          </label>
                        </div>
                        <div className="relative ">
                          <input
                            type="number"
                            id="price"
                            required
                            defaultValue={playground?.price}
                            onChange={handleChange}
                            name="price"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="price"
                            className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Price Per Time Slot
                          </label>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={2}>
                    <div className="mt-4 h-full overflow-x-scroll xl:overflow-hidden py-2">
                      <div className="flex items-center justify-center w-full">
                        <label
                          htmlFor="dropzone-file"
                          className="flex flex-col items-center justify-center w-full h-96 lg:h-[32rem] border-2 border-gray-300 border-dashed rounded-lg 
                      cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100
                     bg-cover bg-center  dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 
                      "
                          style={{
                            backgroundImage: `url('${
                              selectedImage ? selectedImage : playgroundImage
                            }')`,
                          }}
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>{" "}
                              or drag and drop
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              PNG Or JPG
                            </p>
                          </div>

                          <input
                            id="dropzone-file"
                            type="file"
                            name="images[0].image"
                            className="hidden"
                            onChange={(event) => {
                              handleFileChange(event);
                              setFieldValue(
                                `images[0].image`,
                                event.currentTarget.files
                              );
                              setFieldValue(`images[0].is_more`, false);
                              setFieldValue(`images[0].is_cover_image`, true);
                              setFieldValue(
                                `images[0].is_profile_image`,
                                false
                              );
                            }}
                            accept=".png, .jpg, .jpeg"
                            multiple={false}
                          />
                        </label>
                      </div>
                    </div>
                  </TabPanel>
                </TabsBody>
              </Tabs>

              <div className="flex justify-between w-full  !mt-6">
                <button
                  className="linear w-fit  flex space-x-4 rounded-[20px]  
                  px-6 py-2  font-medium text-pgBrand-550 duration-200  hover:bg-pgBrand-550
                    text-sm   border-2 border-pgBrand-500 hover:text-white
                   dark:active:opacity-90 disabled:border-pgBrand-500"
                  onClick={() => navigate(-1)}
                  disabled={isFirstStep}
                  type="button"
                >
                  {/* <IoChevronBackOutline className="h-5 w-5 " /> */}
                  <span> Cancel</span>
                </button>
                <Button
                  loading={loading}
                  disabled={loading}
                  className="linear  flex  rounded-[20px] bg-pgBrand-500 px-6 py-2
                      font-medium text-white  
                     duration-200 w-fit space-x-4 hover:bg-pgBrand-550 active:bg-pgBrand-550
                      text-sm dark:bg-pgBrand-500 
                  dark:hover:bg-pgBrand-550 dark:active:opacity-90 disabled:bg-gray-400"
                  type="submit"
                >
                  <span>Submit</span>
                  <IoChevronForward className="h-5 w-5 " />
                </Button>
              </div>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditPlayground;
