import axios from "axios";
const BASE_URL = "http://102.221.9.19:9191/api/v1/web";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
