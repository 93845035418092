import React, { useEffect, useState } from "react";
import Card from "../../../../components/card";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { FaRegUser, FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import { HiOutlineMapPin } from "react-icons/hi2";
import { BiPhone } from "react-icons/bi";
import PlaygroundCard from "../../../../components/card/PlaygroundCard";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import SVG from "react-inlinesvg";

const FacilityDetails = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  
  const [facility, setFacility] = useState({});
  const [coverImage, setCoverImage] = useState({});
  const [playgrounds, setPlaygrounds] = useState([]);
  const [reviewsData, setReviewsData] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    axiosPrivate
      .get(`/facility/${id}/details/`)
      .then((res) => {
        setFacility(res.data.data);
        setCoverImage(res.data.data.facility_image);
        setPlaygrounds(res.data.data.playgrounds);
      })
      .catch((err) => {
        console.error(err);
      });

    // Fetch facility reviews
    fetchFacilityReviews();
  }, [id]);

  const fetchFacilityReviews = (page = 1) => {
    axiosPrivate
      .get(`/reviews/${id}/facility-reviews-list/?page=${page}`)
      .then((res) => {
        setReviewsData(res.data.data);
        setHasMore(res.data.has_more);
      })
      .catch((err) => {
        console.error("Error fetching reviews:", err);
      });
  };

  // Helper function to render stars horizontally
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return (
      <div className="flex space-x-1">
        {Array(fullStars).fill(<FaStar className="text-pgBrand-200" />)}
        {halfStar ? <FaStarHalfAlt className="text-pgBrand-200" /> : null}
        {Array(emptyStars).fill(<FaRegStar className="text-pgBrand-200" />)}
      </div>
    );
  };

  function formatTime(time) {
    if (!time) return "";
    const dateObj = new Date(`2000-01-01T${time}`);
    return dateObj.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  // Helper function to render percentage bars for ratings
  const renderRatingPercentageBar = (percentage) => (
    <div className="w-full bg-gray-200 rounded-full h-4">
      <div
        className="bg-pgBrand-200 h-4 rounded-full"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div
        className="relative mt-1 flex h-64 md:h-96 w-full justify-center rounded-xl bg-cover bg-center"
        style={{ backgroundImage: `url('${coverImage}')` }}
      >
        {/* Facility Name and Edit Button */}
        <div
          className="absolute  md:-bottom-10 -bottom-36  flex flex-col   space-x-9 lg:h-[100px] w-full 
          md:w-5/6 3xl:w-4/6 h-fit items-center justify-center pt-4
         rounded-t-[40px] lg:rounded-t-full border-[4px] border-white bg-navy-800 dark:!border-navy-800"
        >
          <h1 className="text-3xl font-bold text-white">{facility.name}</h1>
          <div className="lg:flex flex-col lg:flex-row grid grid-cols-1 !mx-0 text-center justify-center lg:space-x-5 mt-4 text-pgBrand-200">
            <div className="flex items-center space-x-2 px-3 py-1 justify-between rounded-2xl">
              <BsClockHistory className="w-5 h-5 " />
              <div className="flex space-x-2 justify-between items-center">
                <p className="w-fit text-base ">{formatTime(facility.open)}</p>
                <p className="w-fit text-base "> - </p>
                <p className="w-fit text-base ">{formatTime(facility.closed)}</p>
              </div>
            </div>
            <div className="flex items-center space-x-2 px-3 py-1 justify-between rounded-2xl ">
              <HiOutlineMapPin className="w-5 h-5 " />
              <div className=" flex space-x-3 justify-between items-center">
                <p className="w-fit text-base  tracking-wide">
                  {facility.location?.address}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-2 px-3 py-1 justify-between rounded-2xl">
              <BiPhone className="w-5 h-5 " />
              <div className=" flex space-x-1 justify-between items-center">
                <p className="w-fit text-base ">{facility.contact_number}</p>
              </div>
            </div>
            <div className="flex items-center space-x-2 px-3 py-1 justify-between rounded-2xl ">
              <FaRegUser className="w-4 h-4 " />
              <div className=" flex space-x-1 justify-between items-center">
                <p className="w-fit text-base ">{facility.supervisor}</p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate(`/admin/facilities/edit/${id}`)}
          className="absolute top-4 right-4 rounded-[20px] flex items-center space-x-3 bg-pgBrand-500 px-4 py-2 font-medium text-white duration-200 hover:bg-pgBrand-550"
        >
          <FiEdit2 />
          <span className="uppercase tracking-wider text-sm">Edit Facility</span>
        </button>
      </div>

      {/* Categories */}
      <div className="w-full">
        <div className="mt-40 lg:mt-16 flex flex-col items-center px-4">
          <div className="flex flex-wrap justify-center mt-4 gap-3">
            {facility.categories?.map((cat) => (
              <div
                key={cat.id}
                className="flex items-center justify-center rounded-full bg-opacity-80 border-pgBrand-500 border p-[1px] text-pgBrand-500 hover:cursor-pointer"
              >
                <div className="flex h-full w-full items-center justify-center rounded-full text-xl dark:text-pgBrand-500">
                  <span className="inline-flex space-x-1 uppercase tracking-wide items-center rounded-xl drop-shadow-sm bg-opacity-10 px-2 text-xs font-medium dark:text-pgBrand-500 ring-inset">
                    <SVG
                      src={cat.icon}
                      alt="My SVG"
                      preProcessor={(code) =>
                        code.replace(/fill=".*?"/g, 'fill="currentColor"')
                      }
                      className="w-5 h-5"
                    ></SVG>
                    <span>{cat.name ? cat.name : "Sport"}</span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Facility Playgrounds */}
      <div className="mb-4 mt-14 flex flex-col w-full justify-between px-4 md:flex-row md:items-center">
        <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
          Facility Playgrounds
        </h4>
      </div>
      <div className="z-20 mt-5 grid grid-cols-1 w-full gap-5 md:grid-cols-3 xl:grid-cols-4">
        {Array.isArray(playgrounds) && playgrounds.length > 0 ? (
          playgrounds.map((pg) => {
            return (
              <PlaygroundCard
                extra={"border border-pgBrand-500"}
                id={pg.id}
                key={pg.id}
                name={pg.name}
                category={pg.category}
                facility={facility}
                image={pg.images.cover_image}
                status={pg.status}
                average_rating={pg.average_rating} // Pass the average rating here
              />
            );
          })
        ) : (
          <p className="text-gray-500">No playgrounds available.</p>
        )}
      </div>


      {/* Ratings and Reviews Section */}
      {reviewsData && (
        <div className="mt-8 w-full px-4">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">Ratings & Reviews</h4>
          <div className="mt-4">
            <div className="flex items-center">
              <span className="text-3xl font-bold text-navy-700 dark:text-white">
                {reviewsData.average_rating.toFixed(1)}
              </span>
              <div className="ml-4">{renderStars(reviewsData.average_rating)}</div>
              <span className="ml-2 text-gray-500">({reviewsData.total_reviews_count} reviews)</span>
            </div>
            <div className="mt-4 space-y-2">
              <div className="flex items-center">
                <span className="w-12 text-sm">5 stars</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.five_stars_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.five_stars_percentage}%
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-12 text-sm">4 stars</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.four_stars_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.four_stars_percentage}%
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-12 text-sm">3 stars</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.three_stars_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.three_stars_percentage}%
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-12 text-sm">2 stars</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.two_stars_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.two_stars_percentage}%
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-12 text-sm">1 star</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.one_star_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.one_star_percentage}%
                </span>
              </div>
            </div>
          </div>

          {/* Review List */}
          <div className="mt-8 grid grid-cols-1 gap-5">
            <h5 className="text-lg font-bold text-navy-700 dark:text-white">User Reviews</h5>
            {reviewsData.reviews.map((review) => (
              <div key={review.id} className="p-4 bg-navy-900 text-white rounded-lg shadow-lg border border-gray-600">
                <div className="flex items-center">
                  {review.user.image ? (
                    <img
                      src={review.user.image}
                      alt={review.user.full_name}
                      className="w-10 h-10 rounded-full"
                    />
                  ) : (
                    <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
                  )}
                  <div className="ml-3">
                    <p className="font-bold">{review.user.full_name}</p>
                    <p className="text-gray-300">
                      {new Date(review.updated_at).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <div className="mt-2">
                  <div className="mt-1">{renderStars(review.rating)}</div>
                  <p className="text-sm text-gray-300 mt-3">{review.review}</p>
                </div>
              </div>
            ))}

            {/* Pagination Button */}
            {hasMore && (
              <div className="flex justify-center mt-6">
                <button
                  onClick={() => fetchFacilityReviews()}
                  className="bg-pgBrand-200 text-white px-4 py-2 rounded-full"
                >
                  Load More Reviews
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

export default FacilityDetails;
