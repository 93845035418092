import React from "react";

// Admin Imports
import MainDashboard from "./views/admin/default";
import NFTMarketplace from "./views/admin/playgrounds";
import Surveys from "./views/admin/surveys";
import Profile from "./views/admin/profile";
import DataTables from "./views/admin/tables";
import RTLDefault from "./views/rtl/default";

// Auth Imports
import SignIn from "./views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdOutlineSportsScore,
} from "react-icons/md";
import SVG from "react-inlinesvg";

import sports from "./assets/img/icons/sports2.svg";
import playground from "./assets/img/icons/playground.svg";
import { FaUsers } from "react-icons/fa";
import Users from "./views/admin/users";
import AddUser from "./views/admin/users/components/AddUser";
import Playground from "./views/admin/playgrounds";
import {
  IoFootballOutline,
  IoPieChartOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import PlaygroundDetails from "./views/admin/playgrounds/components/PlaygroundDetails";
import Marketplace from "./views/admin/marketplace copy";
import Activities from "./views/admin/activities";
import Facilities from "./views/admin/facilities";
import AddFacility from "./views/admin/facilities/components/AddFacility";
import EditFacility from "./views/admin/facilities/components/EditFacility";
import { PiBuildings } from "react-icons/pi";
import { HiOutlineUsers } from "react-icons/hi";
import { LiaUsersCogSolid } from "react-icons/lia";
import AddActivity from "./views/admin/activities/components/AddActivity";
import FacilityDetails from "./views/admin/facilities/components/FacilityDetails";
import AddPlayground from "./views/admin/playgrounds/components/AddPlayground";
import UserDetails from "./views/admin/users/components/UserDetails";
import AddAvailableHours from "./views/admin/playgrounds/components/AddAvailableHours";
import EditPlayground from "./views/admin/playgrounds/components/EditPlayground";
import EditAvailableHours from "./views/admin/playgrounds/components/EditAvailableHours";
import Management from "./views/admin/management";
import CreateSurvey from "./views/admin/surveys/components/CreateSurvey";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import SurveyDetails from "./views/admin/surveys/components/SurveyDetails";
import EditSurvey from "./views/admin/surveys/components/EditSurvey";
import { RiSurveyLine } from "react-icons/ri";



const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <IoPieChartOutline className="h-6 w-6" />,
    component: <MainDashboard />,
    includedInSidebar: true,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <LiaUsersCogSolid className="h-6 w-6" />,
    component: <Users />,
    secondary: true,
    includedInSidebar: true,
  },
  {
    name: "Add User",
    layout: "/admin",
    path: "users/addUser",
    icon: <FaUsers className="h-6 w-6" />,
    component: <AddUser />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "User Details",
    layout: "/admin",
    path: "users/:id",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <UserDetails />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Playgrounds",
    layout: "/admin",
    path: "playgrounds/",
    icon: (
      <SVG
        src={playground}
        alt="My SVG"
        preProcessor={(code) =>
          code.replace(/fill=".*?"/g, 'fill="currentColor"')
        }
        className="w-6 h-6"
      ></SVG>
    ),
    component: <Playground />,
    secondary: true,
    includedInSidebar: true,
  },
  {
    name: "Playground Details",
    layout: "/admin",
    path: "playgrounds/:id",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <PlaygroundDetails />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Add Playground",
    layout: "/admin",
    path: "playgrounds/addPlayground",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <AddPlayground />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Edit Playground",
    layout: "/admin",
    path: "playgrounds/editPlayground/:id",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <EditPlayground />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Add Available Hours",
    layout: "/admin",
    path: "playgrounds/addAvailableHours/:id",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <AddAvailableHours />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Add Available Hours",
    layout: "/admin",
    path: "playgrounds/editAvailableHours/:id",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <EditAvailableHours />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Facilities",
    layout: "/admin",
    path: "facilities",
    icon: <PiBuildings className="h-6 w-6" />,
    component: <Facilities />,
    secondary: true,
    includedInSidebar: true,
  },
  {
    name: "Facility Details",
    layout: "/admin",
    path: "facilities/:id",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <FacilityDetails />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Add Facility",
    layout: "/admin",
    path: "facilities/addFacility",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <AddFacility />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Edit Facility",
    layout: "/admin",
    path: "facilities/edit/:id",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <EditFacility />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Add Activity",
    layout: "/admin",
    path: "activities/addActivity",
    icon: <MdOutlineSportsScore className="h-6 w-6" />,
    component: <AddActivity />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Activities",
    layout: "/admin",
    path: "activities",
    icon: (
      <SVG
        src={sports}
        alt="My SVG"
        preProcessor={(code) =>
          code.replace(/fill=".*?"/g, 'fill="currentColor"')
        }
        className="w-6 h-6"
      ></SVG>
    ),
    component: <Activities />,
    secondary: true,
    includedInSidebar: true,
  },
  {
    name: "Surveys",
    layout: "/admin",
    path: "surveys",
    icon: <RiSurveyLine className="h-6 w-6" />, // You can change this to any icon you prefer
    component: <Surveys />,
    includedInSidebar: true, // Include it in the sidebar menu
  },
  {
    name: "Create Survey",
    layout: "/admin",
    path: "surveys/create",
    component: <CreateSurvey />,
    secondary: true,
    includedInSidebar: false, // We don't want this route in the sidebar
  },
  {
    name: "Survey Details",
    layout: "/admin",
    path: "surveys/:survey_id", // Use the dynamic survey ID
    icon: <MdOutlineQuestionAnswer className="h-6 w-6" />,
    component: <SurveyDetails />, // The component that renders the survey details page
    secondary: true, // If it's a secondary route
    includedInSidebar: false, // If it shouldn't be included in the sidebar
  },
  {
    name: "Edit Survey",
    layout: "/admin",
    path: "surveys/edit/:survey_id",  // Dynamic survey_id
    component: <EditSurvey />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <IoSettingsOutline className="h-6 w-6" />,
    component: <Management />,
    secondary: true,
    includedInSidebar: true,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Marketplace />,
    secondary: true,
    includedInSidebar: false,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
    includedInSidebar: false,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
    includedInSidebar: false,
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "rtl",
    icon: <MdHome className="h-6 w-6" />,
    component: <RTLDefault />,
    includedInSidebar: false,
  },
];
export default routes;
