import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { FaRegUser, FaTimes } from "react-icons/fa";
import Card from "../../../../components/card";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsQuestionCircle } from 'react-icons/bs';
import { BiPhone } from "react-icons/bi";
import SVG from "react-inlinesvg";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Button } from "@material-tailwind/react";

const EditFacilityForm = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [facility, setFacility] = useState({});
  const [updatedFacility, setUpdatedFacility] = useState({ ...facility });
  const [coverImage, setCoverImage] = useState(facility?.image || null);

  const [categoriesDisplay, setCategoriesDisplay] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    axiosPrivate
      .get(`/facility/${id}/details/`)
      .then((res) => {
        const facilityData = res.data.data;
        setFacility({
          ...facility,
          name: facilityData.name,
          status: facilityData.status,
          supervisor: facilityData.supervisor,
          open: facilityData.open,
          closed: facilityData.closed,
          facilityImage: facilityData.facility_image,
          contactNumber: facilityData.contact_number,
          categories: facilityData.categories.map((category) => category.id),
          address: facilityData.location.address,
          longitude: facilityData.location.longitude,
          latitude: facilityData.location.longitude,
          googleMapsShortCode: facilityData.location.code_plus,
        });
        setCoverImage(facilityData.facility_image);

        // Adjust according to the actual image field name
        setCategoriesDisplay(facilityData.categories); // Adjust according to the actual categories field
        //  console.log(facility);
      })
      .catch((err) => {
        console.error(err);
      });

    axiosPrivate
      .get("/facility/categories/")
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedFacility((prevFacility) => ({
      ...prevFacility,
      [name]: value,
    }));
    //  console.log(updatedFacility);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUpdatedFacility({ ...updatedFacility, facility_image: file });
    setCoverImage(URL.createObjectURL(file));
  };

  const handleCategoryChange = (category) => {
    if (categoriesDisplay.includes(category)) {
      setUpdatedFacility({
        ...updatedFacility,
        categories: facility.categories.filter(
          (catId) => catId !== category.id
        ),
      });
      setCategoriesDisplay(
        categoriesDisplay.filter((cat) => cat.id !== category.id)
      );
    } else {
      setUpdatedFacility({
        ...updatedFacility,
        categories: [...facility.categories, category.id],
      });
      setCategoriesDisplay([...categoriesDisplay, category]);
    }
    //  console.log(updatedFacility);
  };

  const handleCategoryRemove = (id) => {
    setUpdatedFacility({
      ...updatedFacility,
      categories: facility.categories.filter((catId) => catId !== id),
    });
    setCategoriesDisplay(categoriesDisplay.filter((cat) => cat.id !== id));
    //  console.log(updatedFacility);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    //  console.log(coverImage);
    //  console.log("hi");

    const formData = new FormData();
    // if (coverImage) {
    //   updatedFacility['facility_image'] = coverImage;
    // }

    // Append only fields that exist in updatedFacility to formData
    for (const key in updatedFacility) {
      if (updatedFacility[key] !== undefined && updatedFacility[key] !== null) {
        formData.append(key, updatedFacility[key]);
      }
    }
    //  console.log(FormData);
    await axiosPrivate
  .patch(`/facility/${id}/update-facility/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  .then((res) => {
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Facility has been updated successfully!",
      confirmButtonText: "Go To Facility",
      confirmButtonColor: "#34EDFF",
      background: "#091442",
      color: "white",
    }).then(() => {
      navigate(`/admin/facilities/${id}`);
    });
  })
  .catch((err) => {
    setLoading(false);

    // Default error message
    let errorMessage = "An unknown error occurred. Please try again.";

    // Check if error response exists and contains data
    if (err.response && err.response.data) {
      const errorData = err.response.data;

      // If there are field-specific errors, format them
      if (typeof errorData === 'object') {
        errorMessage = Object.entries(errorData)
          .map(([field, messages]) => `<strong>${field}</strong>: ${Array.isArray(messages) ? messages.join(', ') : messages}`)
          .join('<br><br>');
      } else if (typeof errorData.message === 'string') {
        // If there's a general error message
        errorMessage = errorData.message;
      }
    }

    // Display the error in Swal
    Swal.fire({
      icon: "error",
      title: "Oops...",
      html: errorMessage, // Using 'html' to support formatting
      confirmButtonText: "Go Back",
      confirmButtonColor: "#45acc4",
      background: "#091442",
      color: "white",
    });

    console.error("Error occurred:", err);
  });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card extra={"w-full h-full p-[16px] bg-cover"}>
        <div
          className="relative mt-1 flex h-64 md:h-96 w-full  justify-center rounded-xl bg-cover  bg-center"
          style={{ backgroundImage: `url('${coverImage}')` }}
        >
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <label className="text-white cursor-pointer">
              Upload Facility Image
              <input
                type="file"
                name="facilityImage"
                onChange={handleFileChange}
                className="hidden"
              />
            </label>
          </div>
          <div
            className="absolute  md:-bottom-10 -bottom-80  flex flex-col   space-x-9 lg:h-[100px] w-full 
          md:w-5/6 3xl:w-4/6 h-fit items-center justify-center pt-4
         rounded-t-[40px] lg:rounded-t-full border-[4px] border-white bg-navy-800 dark:!border-navy-800"
          >
            <div className="relative text-center mt-8">
              <input
                type="text"
                name="name"
                id="name"
                required
                defaultValue={facility?.name}
                onChange={handleInputChange}
                placeholder=""
                className="block px-4 pb-2 pt-3 w-full text-2xl text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
              />
              <label
                htmlFor="name"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Facility Name
              </label>
            </div>
            <div className="lg:flex !mx-0 lg:flex-row grid grid-cols-1 gap-2  w-full justify-center lg:space-x-5 mt-4 text-pgBrand-200">
              <div className="flex items-center space-x-2 lg:px-3 py-1 justify-between rounded-2xl ">
                <div className="relative w-1/2">
                  <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-2.5 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="rgb(0 208 231)"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="time"
                    name="open"
                    required
                    id="open"
                    defaultValue={facility?.open}
                    onChange={handleInputChange}
                    className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
                      rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                      dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                      focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  />
                  <label
                    htmlFor="open"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                      transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                      peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                      peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                      rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Open
                  </label>
                </div>
                <span className="w-fit text-base"> - </span>
                <div className="relative w-1/2">
                  <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-2.5 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="rgb(0 208 231)"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="time"
                    name="closed"
                    required
                    id="closed"
                    defaultValue={facility?.closed}
                    onChange={handleInputChange}
                    className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
                      rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                      dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                      focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  />
                  <label
                    htmlFor="closed"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                      transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                      peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                      peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                      rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Close
                  </label>
                </div>
              </div>
              <div className="flex items-center space-x-2 lg:px-3 py-1 justify-between rounded-2xl">
                <HiOutlineLocationMarker className="lg:w-8 lg:h-8 w-6 h-6 " />
                <div className="relative w-full">
                  <input
                    type="text"
                    name="address"
                    id="address"
                    required
                    defaultValue={facility?.address}
                    onChange={handleInputChange}
                    placeholder=""
                    className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
                      rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                      dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                      focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  />
                  <label
                    htmlFor="address"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                      transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                      peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                      peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                      rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Address
                  </label>
                </div>
              </div>
              <div className="flex items-center space-x-2 lg:px-3 py-1 justify-between rounded-2xl">
                <BiPhone className="lg:w-8 lg:h-8 w-6 h-6 " />
                <div className="relative w-full">
                  <input
                    type="text"
                    id="contactNumber"
                    name="contactNumber"
                    required
                    defaultValue={facility?.contactNumber}
                    onChange={handleInputChange}
                    placeholder=""
                    className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
                    rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                    dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                    focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  />
                  <label
                    htmlFor="contactNumber"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                    peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                    peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                    rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Contact No
                  </label>
                </div>
              </div>
              <div className="flex items-center space-x-2 lg:px-3 py-1 justify-between rounded-2xl ">
                <FaRegUser className="lg:w-8 lg:h-8 w-6 h-6 " />
                <div className="relative w-full">
                  <input
                    type="text"
                    id="supervisor"
                    name="supervisor"
                    required
                    onChange={handleInputChange}
                    defaultValue={facility?.supervisor}
                    placeholder=""
                    className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
                    rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                    dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                    focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  />
                  <label
                    htmlFor="supervisor"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                    peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                    peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                    rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Supervisor
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  mt-80 lg:mt-16 ">
          <div className="mt-6 w-full flex flex-col items-center  lg:px-4">
            <div className="lg:flex flex-wrap w-full grid grid-cols-2 justify-center mt-4 gap-3">
              {categoriesDisplay.map((cat) => (
                <div
                  className="flex  items-center justify-center rounded-full bg-opacity-80 border-pgBrand-500
                  border p-[1px] text-pgBrand-500 hover:cursor-pointer"
                  key={cat.id}
                >
                  <div
                    className="inline-flex space-x-1 uppercase tracking-wide items-center rounded-xl  
                      drop-shadow-sm bg-opacity-10 px-2  text-xs font-medium dark:text-pgBrand-500 
                      ring-inset "
                  >
                    <div className="flex items-center">
                      <SVG
                        src={cat.icon}
                        alt="Category Icon"
                        preProcessor={(code) =>
                          code.replace(/fill=".*?"/g, 'fill="currentColor"')
                        }
                        className="w-5 h-5 mr-2"
                      />
                      <span className="uppercase tracking-wide text-xs font-medium">
                        {cat.name}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="ml-2 p-1 text-red-500 hover:text-red-700"
                      onClick={() => handleCategoryRemove(cat.id)}
                    >
                      <FaTimes />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="relative mb-4 w-full lg:px-10 mt-4">
          <div className="">
            <h2 className="capitalize w-full ">Category</h2>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-2  gap-y-10 gap-x-20 mt-2">
            <div className="relative" ref={dropdownRef}>
              <button
                type="button"
                onClick={toggleDropdown}
                className="relative text-sm text-gray-900 px-2.5 pb-2.5 pt-2.5 w-full cursor-default bg-transparent rounded-lg border border-pgBrand-200 appearance-none dark:dark:text-gray-400  dark:border-pgBrand-200 dark:focus:border-pgBrand-200 focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250"
                aria-haspopup="listbox"
                aria-expanded="true"
              >
                <span className="flex items-center">
                  <span className="ml-3 block truncate">Select a category</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </button>
              {dropdownVisible && (
                <ul
                  className="absolute z-20 mt-1 max-h-56 w-full overflow-auto rounded-md !bg-navy-800 dark:bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  role="listbox"
                >
                  {categories.map((category) => (
                    <li
                      key={category.id}
                      onClick={() => handleCategoryChange(category)}
                      className="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 dark:text-white hover:bg-pgBrand-500 hover:text-white"
                    >
                      <div className="flex items-center">
                        <img
                          src={category.icon}
                          alt=""
                          className="h-5 w-5 flex-shrink-0 rounded-full bg-blue-500"
                        />
                        <span className="ml-3 block truncate font-normal">
                          {category.name}
                        </span>
                      </div>
                      {facility.categories.includes(category.id) && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="relative mb-4 w-full lg:px-10 mt-8">
          <div className="">
            <h2 className="capitalize w-full ">Location Details</h2>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-y-10 lg:gap-x-20 mt-2">
            <div className="relative">
              <input
                type="text"
                name="latitude"
                id="latitude"
                defaultValue={facility?.latitude}
                onChange={handleInputChange}
                placeholder=" "
                className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
              />
              <label
                htmlFor="latitude"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Latitude
              </label>
            </div>

            <div className="relative">
              <input
                type="text"
                name="longitude"
                id="longitude"
                defaultValue={facility?.longitude}
                onChange={handleInputChange}
                placeholder=" "
                className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
              />
              <label
                htmlFor="longitude"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Longitude
              </label>
            </div>
            <div className="relative">
              <input
                type="text"
                name="googleMapsShortCode"
                id="googleMapsShortCode"
                defaultValue={facility?.googleMapsShortCode}
                onChange={handleInputChange}
                placeholder=" "
                className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
              />
              <label
                htmlFor="googleMapsShortCode"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Google Maps Short Code
              </label>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-end">
          <Button
            type="submit"
            loading={loading}
            disabled={loading}
            className="linear rounded-[20px] bg-pgBrand-500 px-12 py-2  font-medium text-white  
            duration-200  hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
            dark:hover:bg-pgBrand-550 dark:active:opacity-90 disabled:bg-gray-400"
          >
            Submit
          </Button>
        </div>
      </Card>
    </form>
  );
};

export default EditFacilityForm;
