import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ForgotPassword from "./views/auth/ForgotPassword";
import Layout from "./views/auth/Layout";
import PersistLogin from "./views/auth/PersistLogin";
import RequireAuth from "./views/auth/RequireAuth";
import AuthLayout from "./layouts/auth/index1";
import AdminLayout from "./layouts/admin";
import RtlLayout from "./layouts/rtl";
import LoginPage from "./views/auth/LoginPage";
import ResetPassword from "./views/auth/ResetPassword";
import CompleteRegistration from "./views/auth/CompleteRegistration";
import Unauthorized from "./views/auth/Unauthorized";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="ForgotPassword" element={<ForgotPassword />} />
        <Route path="ResetPassword" element={<ResetPassword />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route
          path="web/manage/complete-registration/:id"
          element={<CompleteRegistration />}
        />

        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="admin/*" element={<AdminLayout />} />
            <Route path="rtl/*" element={<RtlLayout />} />
            <Route path="/" element={<Navigate to="/admin" replace />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
