import { useState, useEffect } from "react";
import Card from "../../../../components/card";
import CoverImage from "../../../../assets/img/profile/banner.png";
import ProfileImage from "../../../../assets/img/profile/userPlaceholder2.png";
import Swal from "sweetalert2";
import { axiosPrivate } from "../../../../API/axios";
import { Button } from "@material-tailwind/react";

const AccountSettings = ({ user, setUser }) => {
  const [userImage, setUserImage] = useState(user?.image || ProfileImage);
  const [updatedUser, setUpdatedUser] = useState({ ...user });
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUserImage(user.image || ProfileImage);
    setUpdatedUser({ ...user });
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUserImage(reader.result);
      };
      reader.readAsDataURL(file);
      setImageFile(file);
    }
  };

  const fetchUpdatedUser = async (userId) => {
    try {
      const response = await axiosPrivate.get(`/manage/${userId}/view-user/`);
      setUpdatedUser((prevUser) => ({
        ...prevUser,
        phone: response.data.data.profile.phone,
      }));
    } catch (error) {
      console.error("Error fetching updated user data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("first_name", updatedUser.first_name);
    formData.append("last_name", updatedUser.last_name);
    formData.append("phone", updatedUser.phone);
    formData.append("job", updatedUser.job);
    formData.append("description", updatedUser.description);
    if (imageFile) {
      formData.append("image", imageFile);
    }

    axiosPrivate
      .patch(`/manage/user/${user.id}/update/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        setLoading(false);
        await fetchUpdatedUser(user.id);
        Swal.fire({
          icon: "success",
          title: "User information has been updated successfully.",
          confirmButtonText: "Ok",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Something Went Wrong!",
          text: err.response.data.message,
          confirmButtonText: "Go Back",
          confirmButtonColor: "#45acc4",
        });
      });
  };
  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      <Card extra={" p-6"}>
        <div
          className="relative flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl
       shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none w-full "
        >
          <div
            className="h-32 w-full bg-cover bg-center rounded-lg"
            style={{ backgroundImage: `url(${CoverImage})` }}
          ></div>
          <div className="  -mt-20  flex flex-col items-center">
            <div className="relative w-24 h-24 rounded-full overflow-hidden mt-8 mb-1 group">
              <img
                src={userImage}
                alt="Profile"
                className="w-full h-full object-cover group-hover:blur[-2px] transition duration-200"
              />
              <div className="  inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-opacity">
                <span className="text-sm">Edit</span>
              </div>
              <input
                type="file"
                accept="image/*"
                className="  inset-0 w-full h-full opacity-0 cursor-pointer"
                onChange={handleImageChange}
              />
            </div>
            <div className="text-center mt-2">
              <p className="text-lg font-semibold">{user?.username}</p>
              <p className="text-sm text-gray-600 mt-1">
                Account type: Administrator
              </p>
            </div>
          </div>
        </div>

        <h2 className="text-xl font-semibold mb-4 mt-8">Account Settings</h2>
        <div className="">
          <h2 className="capitalize w-full mt-2">Name</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
          <div className="relative">
            <input
              type="text"
              id="first_name"
              name="first_name"
              placeholder=""
              value={updatedUser?.first_name || ""}
              onChange={handleChange}
              className="block px-2.5 pb-2 pt-3 w-full text-sm text-gray-900 bg-transparent 
                         rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                         dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                         focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
            />
            <label
              htmlFor="first_name"
              className="  text-sm text-gray-500 dark:text-gray-400 duration-300 
                         transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                         peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                         peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                         peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                         rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              First Name
            </label>
          </div>
          <div className="relative">
            <input
              type="text"
              id="last_name"
              name="last_name"
              placeholder=""
              value={updatedUser?.last_name || ""}
              onChange={handleChange}
              className="block px-2.5 pb-2 pt-3 w-full text-sm text-gray-900 bg-transparent 
                         rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                         dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                         focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
            />
            <label
              htmlFor="last_name"
              className="  text-sm text-gray-500 dark:text-gray-400 duration-300 
                         transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                         peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                         peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                         peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                         rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Last Name
            </label>
          </div>
        </div>
        <div className="">
          <h2 className="capitalize w-full mt-6">Contact</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
          <div className="relative">
            <input
              id="phone"
              name="phone"
              type="text"
              placeholder=""
              value={updatedUser?.phone || ""}
              onChange={handleChange}
              className="block px-2.5 pb-2 pt-3 w-full text-sm text-gray-900 bg-transparent 
                         rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                         dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                         focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
            />
            <label
              htmlFor="phone"
              className="  text-sm text-gray-500 dark:text-gray-400 duration-300 
                         transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                         peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                         peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                         peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                         rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Phone
            </label>
          </div>
          <div className="relative">
            <input
              type="email"
              id="email"
              placeholder=""
              value={user?.email || ""}
              readOnly
              className="block px-2.5 pb-2 pt-3 w-full text-sm text-gray-900 bg-transparent 
                         rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                         dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                         focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer cursor-not-allowed shadow-inner opacity-50"
            />
            <label
              htmlFor="email"
              className="  text-sm text-gray-500 dark:text-gray-400 duration-300 
                         transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                         peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                         peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                         peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                         rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Email Address
            </label>
          </div>
        </div>
        <div className="">
          <h2 className="capitalize w-full mt-6">Overview</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-2">
          <div className="relative">
            <input
              type="text"
              id="job"
              name="job"
              disabled
              placeholder=""
              value={updatedUser?.job || ""}
              onChange={handleChange}
              className="block px-2.5 pb-2 pt-3 w-full text-sm text-gray-900 bg-transparent 
                         rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                         dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                         focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
            />
            <label
              htmlFor="job"
              className="  text-sm text-gray-500 dark:text-gray-400 duration-300 
                         transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                         peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                         peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                         peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                         rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Job Position
            </label>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-4">
          <div className="relative">
            <textarea
              placeholder=""
              id="description"
              name="description"
              disabled
              value={updatedUser?.description || ""}
              onChange={handleChange}
              className="block px-2.5 pb-2 pt-3 w-full text-sm text-gray-900 bg-transparent 
                         rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                         dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                         focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
              rows="3"
            ></textarea>
            <label
              htmlFor="description"
              className="  text-sm text-gray-500 dark:text-gray-400 duration-300 
                         transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                         peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                         peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-full
                         peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                         rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              About Me
            </label>
          </div>
        </div>
        <div className="flex justify-end mt-8">
          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            className="align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none linear rounded-[20px] bg-pgBrand-500 px-12 py-2 font-medium text-white duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 dark:hover:bg-pgBrand-550 dark:active:opacity-90 disabled:bg-gray-400"
          >
            Save changes
          </Button>
        </div>
      </Card>
    </form>
  );
};

export default AccountSettings;
