import { useState } from "react";
import Card from "../../../../components/card";
import { axiosPrivate } from "../../../../API/axios";
import Swal from "sweetalert2";
import { Button } from "@material-tailwind/react";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleOldPasswordChange = (e) => setOldPassword(e.target.value);
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    validatePasswords(e.target.value, confirmPassword);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validatePasswords(newPassword, e.target.value);
  };

  const validatePasswords = (newPass, confirmPass) => {
    if (!newPass || !confirmPass) {
      setError("");
      setDisabled(true);
    } else if (newPass !== confirmPass) {
      setError("New passwords do not match");
      setDisabled(true);
    } else {
      setError("");
      setDisabled(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    axiosPrivate
      .post("/auth/change_password/", {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      })
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Password has been updated successfully!",
          confirmButtonText: "Go To Profile Settings",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Something Went Wrong!",
          text: err.response.data.message,
          confirmButtonText: "Back",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        });
      });
  };

  return (
    <Card extra={"p-6"}>
      <h2 className="text-xl font-semibold mb-4">Change Password</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="relative">
          <input
            id="old_password"
            type="password"
            placeholder=""
            value={oldPassword}
            onChange={handleOldPasswordChange}
            className="block px-2.5 pb-2 pt-3 w-full text-sm text-gray-900 bg-transparent 
                      rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                      dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                      focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
          />
          <label
            htmlFor="old_password"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                      transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                      peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                      peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                      rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Old Password
          </label>
        </div>
        <div className="relative">
          <input
            id="new_password"
            type="password"
            placeholder=""
            value={newPassword}
            onChange={handleNewPasswordChange}
            className="block px-2.5 pb-2 pt-3 w-full text-sm text-gray-900 bg-transparent 
                      rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                      dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                      focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
          />
          <label
            htmlFor="new_password"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                      transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                      peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                      peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                      rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            New Password
          </label>
        </div>
        <div className="relative">
          <input
            id="confirm_password"
            type="password"
            placeholder=""
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className="block px-2.5 pb-2 pt-3 w-full text-sm text-gray-900 bg-transparent 
                      rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                      dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                      focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
          />
          <label
            htmlFor="confirm_password"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                      transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                      peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                      peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                      rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Confirm Password
          </label>
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <div className="flex justify-end mt-8">
          <Button
            type="submit"
            loading={loading}
            disabled={disabled || loading}
            className="align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none linear rounded-[20px] bg-pgBrand-500 px-8 py-2 font-medium text-white duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 dark:hover:bg-pgBrand-550 dark:active:opacity-90 disabled:bg-gray-400"
          >
            Change Password
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default ChangePassword;
