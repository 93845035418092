import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaPlus, FaTrashAlt } from "react-icons/fa"; // Add Trash icon for remove button
import { IoChevronForward } from "react-icons/io5";
import { BsQuestionCircle } from 'react-icons/bs';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Button } from "@material-tailwind/react";
import Card from "../../../../components/card";
import Swal from "sweetalert2";

const EditSurvey = () => {
  const { survey_id } = useParams(); // Get the survey ID from the URL params
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [survey, setSurvey] = useState({
    name: "",
    description: "",
    editable: false,
    deletable: false,
    duplicate_entry: false,
    private_response: false,
    can_anonymous_user: false,
    notification_to: "",
  });

  // Fetch existing survey data
  useEffect(() => {
    axiosPrivate
      .get(`/survey/${survey_id}/survey-view/`)
      .then((res) => {
        const surveyData = res.data.data;
        setSurvey({
          name: surveyData.name,
          description: surveyData.description,
          editable: surveyData.editable,
          deletable: surveyData.deletable,
          duplicate_entry: surveyData.duplicate_entry,
          private_response: surveyData.private_response,
          can_anonymous_user: surveyData.can_anonymous_user,
          notification_to: surveyData.notification_to,
        });
        // Ensure questions contain their ID
        setQuestions(surveyData.questions);
      })
      .catch((err) => console.error(err));
  }, [survey_id, axiosPrivate]);

  // Fetch question types
  useEffect(() => {
    axiosPrivate
      .get("/survey/question-type-list/")
      .then((res) => {
        setQuestionTypes(res.data.data);
      })
      .catch((err) => console.error(err));
  }, [axiosPrivate]);

  const handleSurveyChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setSurvey({ ...survey, [name]: inputValue });
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = questions.map((question, idx) =>
      idx === index ? { ...question, [field]: value } : question
    );
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        id: null, // No ID for newly added questions (let the API handle it)
        key: `question_${questions.length + 1}`,
        label: "",
        type_field: 0,
        choices: "", // Initialize choices
        help_text: "",
        required: false,
        ordering: questions.length + 1,
      },
    ]);
  };

  // Handle removing a question
  const removeQuestion = (index) => {
    const questionId = questions[index]?.id; // Get the question's ID
  
    // Check if the question has an ID (only send API request for existing questions)
    if (!questionId) {
      // For newly added questions that haven't been saved yet, just remove them from state
      const updatedQuestions = questions.filter((_, idx) => idx !== index);
      setQuestions(updatedQuestions);
      Swal.fire({
        icon: "success",
        title: "Question removed!",
        background: "#091442",
        iconColor: "#F559FB",
        color: "white",
      });
      return;
    }
  
    // Confirm with Swal
    Swal.fire({
      title: "Are you sure you want to remove this question?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F559FB",
      cancelButtonColor: "#adb5bd",
      confirmButtonText: "Yes, remove it!",
      background: "#091442",
      iconColor: "#F559FB",
      color: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        // Make the DELETE API call
        axiosPrivate
          .delete(`/survey/${questionId}/delete-question/`)
          .then(() => {
            // If successful, remove the question from the state
            const updatedQuestions = questions.filter((_, idx) => idx !== index);
            setQuestions(updatedQuestions);
  
            Swal.fire({
              icon: "success",
              title: "Question removed!",
              background: "#091442",
              iconColor: "#F559FB",
              color: "white",
            });
          })
          .catch((err) => {
            console.error("Error removing question:", err);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "There was an issue removing the question. Please try again.",
              background: "#091442",
              iconColor: "#F559FB",
              color: "white",
            });
          });
      }
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const surveyData = {
      ...survey,
      questions,
    };

    try {
      await axiosPrivate.patch(`/survey/${survey_id}/update-survey/`, surveyData); // Ensure survey_id is part of the URL
      Swal.fire({
        icon: "success",
        title: "Survey Updated Successfully!",
        confirmButtonText: "Go to Surveys",
        background: "#091442",
        iconColor: "#F559FB",
        color: "white",
      }).then(() => navigate("/admin/surveys"));
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue updating the survey. Please try again.",
        background: "#091442",
        iconColor: "#F559FB",
        color: "white",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
      <Card extra={"w-full h-full p-6 sm:overflow-x-auto"}>
        <form onSubmit={handleSubmit}>
          <h1 className="text-2xl font-bold mb-4">Edit Survey</h1>

          {/* Survey Details */}
          <div className="mb-6">
            <div className="mb-4">
              <label className="block text-xl font-bold dark:text-white">Survey Name</label>
              <input
                type="text"
                name="name"
                className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                value={survey.name}
                onChange={handleSurveyChange}
                placeholder="Survey Name"
              />
            </div>

            <div className="mb-4">
              <label className="block text-xl font-bold dark:text-white">Description</label>
              <textarea
                name="description"
                className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                value={survey.description}
                onChange={handleSurveyChange}
                placeholder="Survey Description"
              />
            </div>

            <div className="mb-4">
              <label className="block text-xl font-bold dark:text-white">Notification Email</label>
              <input
                type="email"
                name="notification_to"
                className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                value={survey.notification_to}
                onChange={handleSurveyChange}
                placeholder="Notification Email"
              />
            </div>

            {/* Admin-Controllable Checkboxes */}
            <div className="mb-4 grid grid-cols-2 gap-4">
              <label className="dark:text-white flex items-center text-xl font-medium">
                <input
                  type="checkbox"
                  name="editable"
                  checked={survey.editable}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                Editable
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Allows the user to edit their answer after submission."
                />
              </label>

              <label className="dark:text-white flex items-center text-xl font-medium">
                <input
                  type="checkbox"
                  name="deletable"
                  checked={survey.deletable}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                Deletable
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Allows the user to delete their answer after submission."
                />
              </label>

              <label className="dark:text-white flex items-center text-xl font-medium">
                <input
                  type="checkbox"
                  name="duplicate_entry"
                  checked={survey.duplicate_entry}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                Allow Duplicate Entries
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Allows users to submit multiple responses."
                />
              </label>

              <label className="dark:text-white flex items-center text-xl font-medium">
                <input
                  type="checkbox"
                  name="private_response"
                  checked={survey.private_response}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                Private Response
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Hides the response from other users."
                />
              </label>

              <label className="dark:text-white flex items-center text-xl font-medium">
                <input
                  type="checkbox"
                  name="can_anonymous_user"
                  checked={survey.can_anonymous_user}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                Allow Anonymous User
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Allows users to submit responses without logging in."
                />
              </label>
            </div>
          </div>

          {/* Questions Section */}
          <div className="mb-6">
            <h2 className="text-xl font-bold mb-4 dark:text-white">Questions</h2>
            {questions.map((question, index) => (
              <div key={index} className="mb-4">
                {/* Question Key Input */}
                <div className="mb-2">
                  <label className="block text-xl font-bold text-gray-700 dark:text-white">Question Key</label>
                  <input
                    type="text"
                    className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                    value={question.key}
                    onChange={(e) => handleQuestionChange(index, "key", e.target.value)}
                    placeholder="Enter the question key"
                  />
                </div>

                {/* Question Label */}
                <div className="mb-2">
                  <label className="block text-xl font-bold text-gray-700 dark:text-white">The Question</label>
                  <input
                    type="text"
                    className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                    value={question.label}
                    onChange={(e) => handleQuestionChange(index, "label", e.target.value)}
                    placeholder="Enter the question"
                  />
                </div>

                {/* Help Text */}
                <div className="mb-2">
                  <label className="text-xl font-bold text-gray-700 dark:text-white flex items-center">
                    Help Text
                    <BsQuestionCircle
                      className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                      title="Filling this help text will display a small tooltip icon next to the question, providing users with additional guidance or clarification."
                    />
                  </label>
                  <input
                    type="text"
                    className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                    value={question.help_text}
                    onChange={(e) => handleQuestionChange(index, "help_text", e.target.value)}
                    placeholder="Help text (optional)"
                  />
                </div>

                {/* Question Type */}
                <div className="relative mb-2">
                  <label className="block text-xl font-bold leading-6 text-gray-900 dark:text-white">Question Type</label>
                  <select
                    value={question.type_field}
                    onChange={(e) => handleQuestionChange(index, "type_field", parseInt(e.target.value))}
                    className="relative text-lg text-gray-900 px-2.5 pb-2.5 pt-2.5 w-full cursor-default bg-transparent rounded-lg border border-pgBrand-200 appearance-none dark:text-white dark:border-pgBrand-200 focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250"
                    aria-haspopup="listbox"
                    aria-expanded="true"
                  >
                    {questionTypes.map((type) => (
                      <option
                        key={type.id}
                        value={type.id}
                        className="bg-navy-800 text-lg text-gray-900 dark:text-white hover:bg-pgBrand-500 hover:text-white cursor-pointer"
                      >
                        {type.type}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Choices Input (conditional rendering) */}
                {(parseInt(question.type_field) === 3 || parseInt(question.type_field) === 4) && (
                  <div className="mb-2">
                    <label className="block text-xl font-bold text-gray-700 dark:text-white">Choices (comma-separated)</label>
                    <input
                      type="text"
                      className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                      value={question.choices}
                      onChange={(e) => handleQuestionChange(index, "choices", e.target.value)}
                      placeholder="Enter choices (e.g., Choice A, Choice B, Choice C)"
                    />
                  </div>
                )}

                {/* Required Checkbox */}
                <div className="mb-2">
                  <label className="text-xl font-bold text-gray-700 dark:text-white flex items-center">
                    <input
                      type="checkbox"
                      checked={question.required}
                      onChange={(e) => handleQuestionChange(index, "required", e.target.checked)}
                      className="mr-2"
                    />
                    Required
                  </label>
                </div>

                {/* Remove Button */}
                <button
                  type="button"
                  onClick={() => removeQuestion(index)} // Trigger question removal
                  className="flex items-center space-x-2 text-red-500 hover:text-red-600"
                >
                  <FaTrashAlt />
                  <span>Remove Question</span>
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addQuestion}
              className="flex items-center space-x-2 p-2 bg-blue-500 text-white rounded-lg"
            >
              <FaPlus />
              <span>Add Question</span>
            </button>
          </div>


          {/* Submit Survey */}
          <div className="flex justify-between w-full">
            <Button
              type="submit"
              disabled={loading}
              className="flex items-center rounded-[20px] bg-pgBrand-500 px-6 py-2 font-medium text-white duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 disabled:bg-gray-400"
            >
              {loading ? "Updating..." : "Update Survey"}
              <IoChevronForward className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditSurvey;
