import React, { useEffect, useState } from "react";
import Card from "../../../../components/card";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "@material-tailwind/react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { MdBlockFlipped, MdCancel, MdOutlineCancel } from "react-icons/md";
import Swal from "sweetalert2";

function AddUser() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    axiosPrivate.get("/manage/roles/").then((res) => {
      setRoles(res.data.data);
    });
  }, [selectedRoles]);
  
  const createUser = (e) => {
    e.preventDefault();
    setLoading(true); // Disable the button and set loading to true
  
    const data = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      role_ids: selectedRoles.map((sr) => sr.id),
    };
  
    axiosPrivate
      .post("/manage/create-profile/", data)
      .then((res) => {
        console.log(res); // Log the response
        Swal.fire({
          icon: "success",
          title: "New user has been added successfully!",
          confirmButtonText: "Go To Users List",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        }).then(() => {
          setLoading(false); // Re-enable the button after success
          navigate("/admin/users");
        });
      })
      .catch((err) => {
        setLoading(false); // Re-enable the button after error
  
        if (err.response) {
          if (err.response.status === 400 && err.response.data?.email) {
            Swal.fire({
              icon: "error",
              title: "Email already exists!",
              text: "Please try again with a different email.",
              confirmButtonText: "OK",
              confirmButtonColor: "#FF6347",
              background: "#091442",
              color: "white",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Something went wrong!",
              text: err.response.data?.message || "Please try again later.",
              confirmButtonText: "OK",
              confirmButtonColor: "#FF6347",
              background: "#091442",
              color: "white",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Network Error",
            text: "Please check your connection and try again.",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF6347",
            background: "#091442",
            color: "white",
          });
        }
      });
  };
  
  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
      <form onSubmit={createUser}>
        <Card extra={"w-full h-full p-6 sm:overflow-x-auto"}>
          <div className="relative flex items-center justify-between">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Add New User
            </div>
            <div>
              {/* <button
              onClick={() => navigate("/admin/user/addUser")}
              className="linear rounded-[20px] bg-pgBrand-500 px-8 py-2  font-medium text-white  
          duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
          dark:hover:bg-pgBrand-550 dark:active:opacity-90"
            >
              Save and add another
            </button> */}
            </div>
          </div>

          <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden py-2">
            <div className="">
              <h2 className="capitalize w-full ">name</h2>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div className="relative ">
                <input
                  type="text"
                  required
                  id="first_name"
                  name="first_name"
                  className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  placeholder=""
                />
                <label
                  htmlFor="first_name"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  First Name
                </label>
              </div>

              <div className="relative ">
                <input
                  type="text"
                  id="last_name"
                  required
                  name="last_name"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="last_name"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Last Name
                </label>
              </div>
            </div>
            <div className="">
              <h2 className="capitalize w-full mt-8">Contact </h2>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div className="relative ">
                <input
                  type="text"
                  id="email"
                  required
                  name="email"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="email"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Email Address
                </label>
              </div>
              <div className="relative ">
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              

              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="phone"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Phone Number
                </label>
              </div>
            </div>
            <div className="">
              <h2 className="capitalize w-full mt-8">Role </h2>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div className="relative ">
                <select
                  id="role"
                  name="role_ids"
                  required
                  onChange={(e) => {
                    const { value: id, text: name } =
                      e.target.selectedOptions[0];
                    const newRole = { id: parseInt(id), name };

                    if (!selectedRoles.some(({ id }) => id === newRole.id)) {
                      setSelectedRoles([...selectedRoles, newRole]);
                    }
                  }}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200 appearance-none dark:text-white dark:bg-navy-800
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  placeholder=""
                >
                  <option selected disabled>
                    Select A Role
                  </option>
                  {roles
                    // .filter((role) => !selectedRoles.includes(role.name))
                    .map((role) => {
                      return (
                        <option value={role.id} key={role.id}>
                          {role.name}
                        </option>
                      );
                    })}
                </select>
                <label
                  htmlFor="role"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Role
                </label>
                <div className="flex space-x-2 mt-3">
                  {selectedRoles
                    ? selectedRoles.map((sr) => {
                        return (
                          <span
                            className="flex justify-between w-fit space-x-3 tracking-wide items-center rounded-xl
                          drop-shadow-sm bg-opacity-10 px-2 py-1
                         text-xs font-medium  ring-1 ring-inset ring-pgBrand-200"
                            key={sr.id}
                          >
                            <div
                              className={`rounded-full text-lg cursor-pointer`}
                              onClick={() => {
                                const updatedRoles = selectedRoles.filter(
                                  (role) => role.id !== sr.id
                                );
                                setSelectedRoles(updatedRoles);
                              }}
                            >
                              <MdOutlineCancel className="text-gray-50" />
                            </div>
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {sr.name}
                            </p>
                          </span>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-3 ">
              <Button
                type="submit"
                loading={loading}
                disabled={loading}
                className="linear rounded-[20px] bg-pgBrand-500 px-12 py-2  font-medium text-white  
                     duration-200  hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
                  dark:hover:bg-pgBrand-550 dark:active:opacity-90 disabled:bg-gray-400"
              >
                Save
              </Button>
            </div>
          </div>
        </Card>
      </form>
    </div>
  );
}

export default AddUser;
