import { useState } from "react";
import Card from ".";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const PlaygroundCard = ({
  id,
  name,
  category,
  image,
  status,
  average_rating, // New prop for average rating
  total_reviews_count, // New prop for total reviews count
  extra,
}) => {
  const navigate = useNavigate();

  // Helper function to render stars based on the rating
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return (
      <div className="flex space-x-1">
        {Array(fullStars).fill(<FaStar className="text-pgBrand-200" />)}
        {halfStar ? <FaStarHalfAlt className="text-pgBrand-200" /> : null}
        {Array(emptyStars).fill(<FaRegStar className="text-pgBrand-200" />)}
      </div>
    );
  };

  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white ${
        status.id === 4 ? "!border !border-gray-300 !border-opacity-40" : ""
      } ${extra}`}
    >
      <div className="h-full w-full">
        <div className="relative w-full">
          <img
            src={image}
            className="mb-3  h-[250px] 3xl:h-[300px] w-full rounded-xl "
            alt=""
            onClick={() => {
              navigate(`/admin/playgrounds/${id}`);
            }}
          />
          <div className="absolute top-3 right-3 flex flex-col space-y-1">
            <div
              className=" flex items-center justify-center rounded-full bg-pgBrand-200
                     bg-opacity-80 border-pgBrand-200 border-2 p-1 text-brand-500 hover:cursor-pointer"
            >
              <div
                className="flex justify-center h-full w-full 
                    items-center  rounded-full text-xl  dark:text-pgBrand-250"
              >
                <span
                  className="inline-flex    space-x-1 uppercase tracking-wide items-center rounded-xl  
              drop-shadow-sm bg-opacity-10 px-2  text-xs font-semibold text-navy-800 
               ring-inset "
                >
                  <SVG
                    src={category.icon}
                    alt="My SVG"
                    preProcessor={(code) =>
                      code.replace(/fill=".*?"/g, 'fill="currentColor"')
                    }
                    className="w-5 h-5"
                  ></SVG>
                  <span>{category.name ? category.name : "Sport"}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Playground Name and Status */}
        <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-2 w-full">
            <div className="flex justify-start space-x-3">
              <p className="text-2xl font-bold font-mono  text-navy-700 dark:text-white">
                {name}
              </p>
              {status.id !== 1 && (
                <div
                  className={`flex items-center justify-center rounded-full h-fit px-1 py-[2px] border-2 ${
                    status.id === 4
                      ? "bg-gray-300 bg-opacity-50 border-gray-300 text-gray-200"
                      : "bg-yellow-400 bg-opacity-45 border-yellow-400 text-yellow-300"
                  }`}
                >
                  <span className="inline-flex space-x-1  tracking-wider items-center rounded-xl drop-shadow-sm bg-opacity-10 px-2  text-xs ring-inset">
                    {status.name}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Review Rating and Count Section */}
        <div className="mt-4">
          <p className="text-md font-bold text-gray-700 dark:text-white">
            Reviews: {total_reviews_count}
          </p>
          <div className="average-rating flex items-center mt-1">
            <p className="text-md font-bold text-gray-700 dark:text-white">Rating: </p>
            <span className="ml-2 text-lg font-semibold flex items-center">
              {renderStars(average_rating)}
              <span className="ml-2 text-md">({average_rating.toFixed(1)})</span>
            </span>
          </div>
        </div>

        {/* View Playground Button */}
        <div className="flex justify-end mt-2">
          <button
            onClick={() => {
              navigate(`/admin/playgrounds/${id}`);
            }}
            className={`linear rounded-[20px] bg-brand-900 px-4 py-2 text-sm tracking-wide font-medium
            transition duration-200 dark:active:opacity-90
            ${
              status.id === 4
                ? "hover:bg-gray-200 dark:bg-gray-300  text-gray-900  dark:hover:bg-gray-400"
                : "hover:bg-pgBrand-550 dark:bg-pgBrand-500 text-white  dark:hover:bg-pgBrand-550"
            }`}
          >
            View Playground
          </button>
        </div>
      </div>
    </Card>
  );
};

export default PlaygroundCard;
