import React, { useEffect, useState } from "react";
import Card from "../../../../components/card";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "@material-tailwind/react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { MdBlockFlipped, MdCancel, MdOutlineCancel } from "react-icons/md";
import Swal from "sweetalert2";
import { Field, Form, Formik } from "formik";
import SVG from "react-inlinesvg";
import * as Yup from "yup";

function AddActivity() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [logoChanging, setLogoChanging] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState([]);

  const validationSchema = Yup.object().shape({
    icon: Yup.mixed()
      .required("Icon Is Required")
      .test("fileType", "Only SVG files are allowed", (value) => {
        if (value) {
          return value.type === "image/svg+xml";
        }
        return true;
      }),

    name: Yup.string().required("Name Is Required"),
  });

  const createActivity = (data) => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    if (data.icon) {
      bodyFormData.append("icon", data.icon);
    }
    if (data.description) {
      bodyFormData.append("description", data.description);
    }
  
    axiosPrivate
      .post("/facility/create-category/", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "New activity has been added successfully!",
          confirmButtonText: "Go To Activities List",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        }).then(() => {
          navigate("/admin/activities");
        });
      })
      .catch((err) => {
        setLoading(false);
        const errorResponse = err.response?.data;
        let errorMessage = "Please fix the following errors:\n\n";
        
        // Extract field errors if available
        if (errorResponse && typeof errorResponse === "object") {
          for (const [key, value] of Object.entries(errorResponse)) {
            errorMessage += `<strong>${key.charAt(0).toUpperCase() + key.slice(1)}:</strong> ${value.join(", ")}<br/>`;
          }
        }
  
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: errorMessage,  // Use 'html' to render formatted error messages
          confirmButtonText: "Go Back",
          confirmButtonColor: "#45acc4",
          background: "#091442",
          color: "white",
        });
      });
  
    console.log(data);
  };
  
  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
      <Formik
        onSubmit={createActivity}
        initialValues={{ name: "", icon: "", description: "" }}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Card extra={"w-full h-full p-6 sm:overflow-x-auto"}>
              <div className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                  Add New Activity
                </div>
                <div>
                  {/* <button
              onClick={() => navigate("/admin/activities/addActivity")}
              className="linear rounded-[20px] bg-pgBrand-500 px-8 py-2  font-medium text-white  
          duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
          dark:hover:bg-pgBrand-550 dark:active:opacity-90"
            >
              Save and add another
            </button> */}
                </div>
              </div>

              <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden py-2">
                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-y-3 lg:gap-8 mt-2">
                  <div className="relative  ">
                    <div className="mb-3">
                      <h2 className="capitalize w-full ">Icon</h2>
                    </div>
                    <div className="file_upload  p-5 relative border-pgBrand-200 border rounded-lg">
                      {file ? (
                        <span className="text-pgBrand-500 ">
                          <SVG
                            src={file}
                            alt="My SVG"
                            preProcessor={(code) =>
                              code.replace(/fill=".*?"/g, 'fill="currentColor"')
                            }
                            className="w-20 h-20 mx-auto mb-6"
                          ></SVG>
                        </span>
                      ) : (
                        <svg
                          className="text-pgBrand-500 w-24 mx-auto mb-4"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                      )}
                      <div className="input_field flex flex-col w-max mx-auto text-center">
                        <label>
                          <input
                            type="file"
                            id="logoU"
                            hidden
                            accept=".svg"
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file) {
                                setFieldValue("icon", file);
                                setFile(URL.createObjectURL(file));
                              }
                            }}
                          />

                          <div
                            className="text bg-pgBrand-500 text-white rounded
                           text-xs tracking-wider cursor-pointer p-1 px-6 hover:bg-pgBrand-550"
                          >
                            Upload Icon
                          </div>
                        </label>
                        <span className="text-xs tracking-wider mt-2 text-slate-400">
                          only SVG files accepted
                        </span>{" "}
                        {errors.icon && (
                          <span className="text-red-500 text-sm">
                            {errors.icon}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="mb-3">
                      <h2 className="capitalize w-full ">Name</h2>
                    </div>
                    <div className="relative ">
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                        placeholder=""
                      />
                      <label
                        htmlFor="name"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Activity Name
                      </label>
                    </div>
                    {errors.name && (
                      <span className="text-red-500 text-sm">
                        {errors.name}
                      </span>
                    )}
                    <div className="mt-8 mb-3">
                      <h2 className="capitalize w-full ">Description</h2>
                    </div>
                    <div className="relative  mt-2">
                      <textarea
                        id="description"
                        name="description"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                        rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                      

                      dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                        focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                        placeholder=" "
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                      />
                      <label
                        htmlFor="description"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                        transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                        peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                        peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                        rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Description
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mt-10 ">
                  <Button
                    type="submit"
                    loading={loading}
                    disabled={loading}
                    className="linear rounded-[20px] bg-pgBrand-500 px-12 py-2  font-medium text-white  
                     duration-200  hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
                  dark:hover:bg-pgBrand-550 dark:active:opacity-90 disabled:bg-gray-400"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddActivity;
