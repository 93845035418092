import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { useState } from "react";
import Card from "../card";
import SVG from "react-inlinesvg";
import { Form, useNavigate } from "react-router-dom";
import CardMenu from "./CardMenu";
import { Formik } from "formik";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import Swal from "sweetalert2";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const ActivityCard = ({
  name,
  id,
  price,
  icon,
  bidders,
  getActivity,
  extra,
}) => {
  const [heart, setHeart] = useState(true);
  const navigate = useNavigate();

  return (
    <Card
      extra={`flex group overflow-hidden flex-col items-center border cursor-pointer
       border-pgBrand-500 w-full h-full !p-6 3xl:p-![18px] 
      dark:!bg-navy-700 ${extra}`}
      onClick={() => {
        getActivity(id);
      }}
    >
      <div className="h-full   group-hover:w-full group ">
        <div className="relative w-full">
          <span className="text-pgBrand-500 drop-shadow-xl">
            <SVG
              src={icon}
              alt="My SVG"
              preProcessor={(code) => {
                const fillRegex = /fill=".*?"/;
                const strokeRegex = /stroke=".*?"/;
                const processedCode = code.replace(
                  /fill=".*?"/g,
                  'fill="currentColor"'
                );
                return processedCode;
              }}
              className="w-3/6 h-auto mx-auto"
            ></SVG>
          </span>
        </div>
      </div>
      <div
        className="absolute text-slate-100 bg-navy-800 bg-opacity-75 h-full top-0 w-full 
        text-center items-center justify-center flex flex-col space-y-1 group-hover:opacity-0 
        !transition-opacity !duration-300"
      >
        <p className="text-3xl xl:text-2xl  3xl:text-3xl font-semibold  ">
          {name}
        </p>
      </div>
    </Card>
  );
};

export default ActivityCard;
